import React, { useContext, useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as farCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircle as faCircle } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../shared/context/auth-context";
import ErrorModal from "../../shared/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/UIElements/LoadingSpinner";
import Card from "../../shared/UIElements/Card";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StockCard from "../img/AdaptLogo.png";
import BackStockCard from "../img/back-logo-img.png";

import "./SignupForm.css";
import PaymentForm from "./PaymentForm";
import UserInfo from "./SignupComponents/UserInfo";
import CardImageSelect from "./SignupComponents/CardImageSelect";

export const paymentContext = createContext();

const LogFormValues = ({ setUsernameAvailable, setEmailAvailable }) => {
  const formik = useFormikContext();
  const [error, setError] = useState(null);

  // Log form values whenever they change
  useEffect(() => {
    const val = formik.values.username;

    if (val.length >= 3) {
      setTimeout(async () => {
        await axios
          .get(
            `${process.env.REACT_APP_BACKEND_API}/api/user/validate-username/${val}`
          )
          .then((response) => {
            if (response.data.message === "Available") {
              setUsernameAvailable(true);
            } else {
              setUsernameAvailable(false);
            }
          })
          .catch((err) => {
            setError(err.message);
          });
      }, 1200);
    }
  }, [formik.values.username]);

  useEffect(() => {
    const val = formik.values.email;

    if (val.length >= 3) {
      setTimeout(async () => {
        await axios
          .get(
            `${process.env.REACT_APP_BACKEND_API}/api/user/validate-email/${val}`
          )
          .then((response) => {
            if (response.data.message === "Available") {
              setEmailAvailable(true);
            } else {
              setEmailAvailable(false);
            }
          })
          .catch((err) => {
            setError(err);
          });
      }, 1200);
    } else {
      setEmailAvailable(null);
    }
  }, [formik.values.email]);

  return null;
};

const SignupForm = ({ type, amount, setSelection }) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [hasPaid, setHasPaid] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cardImage, setCardImage] = useState(StockCard);
  const [backCardImage, setBackCardImage] = useState(BackStockCard);
  const [formIndex, setFormIndex] = useState(1);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  //const [amount, setAmount] = useState({ amount: 4000 });
  const [usernameAvailable, setUsernameAvailable] = useState(null);
  const [emailAvailable, setEmailAvailable] = useState(false);

  const initialValues = {
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    theme: "",
    cardtype: "",
    cardimage: cardImage,
    backcardimage: backCardImage,
    password: ""
  };

  //create user function
  const createUser = async (values) => {
    const formData = new FormData();
    formData.append("firstname", values.firstname);
    formData.append("lastname", values.lastname);
    formData.append("username", values.username);
    formData.append("email", values.email);
    formData.append("theme", values.theme);
    formData.append("cardtype", type);
    formData.append("cardimage", values.cardimage);
    formData.append("backcardimage", values.backcardimage);
    formData.append("password", values.password);

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/api/user/signup`,
        formData
      );
      setUser(response.data);
      setIsLoading(false);
      createOrder(
        response.data.userId,
        response.data.email,
        response.data.cardimage,
        response.data.backcardimage
      );

      auth.login(
        response.data.userId,
        response.data.token,
        response.data.userType
      );
      navigate(`/admin/${response.data.userId}`);
    } catch (err) {
      setIsLoading(false);
      setError(err.response.data.message);
    }

    setIsLoading(false);
  };

  const createOrder = async (userId, userEmail, cardimage, backcardimage) => {
    const newOrder = {
      amount: paymentDetails.amount,
      created: paymentDetails.created,
      id: paymentDetails.id,
      userId: userId,
      email: userEmail,
      shipping: {
        name: paymentDetails.shipping.name,
        phone: paymentDetails.shipping.phone,
        address: {
          line1: paymentDetails.shipping.address.line1,
          line2: paymentDetails.shipping.address.line2,
          city: paymentDetails.shipping.address.city,
          state: paymentDetails.shipping.address.state,
          country: paymentDetails.shipping.address.country,
          postal_code: paymentDetails.shipping.address.postal_code
        }
      },
      cardimage: cardimage,
      backcardimage: backcardimage
    };

    await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/api/order/create-order`,
      newOrder,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required("REQUIRED"),
    lastname: Yup.string().required("REQUIRED"),
    username: Yup.string()
      .required("REQUIRED")
      .min(3, "Please enter at least 3 characters."),
    email: Yup.string().email("Invalid email format.").required("REQUIRED"),
    password: Yup.string()
      .min(6, "Password must be between 6-15 characters.")
      .max(15, "Password is too long - Must be between 6-15 characters.")
      .required("REQUIRED")
  });

  const onSignupHandler = async (values) => {
    createUser(values);
  };

  const onHandleError = () => {
    setError(null);
  };

  useEffect(() => {
    if (hasPaid) {
      const submitButton = document.getElementById("submit-btn");
      submitButton.click();
      // const submitForm = document.getElementById("signup_form__formik");
      // submitForm.submit();
    }
  }, [hasPaid]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/api/payment/config`)
      .then((response) => {
        setStripePromise(loadStripe(response.data.publishableKey));
      })
      .catch((err) => setError(err));
  }, []);

  useEffect(() => {
    if (amount.amount > 0) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_API}/api/payment/pay`, amount)
        .then((response) => {
          setClientSecret(response.data.clientSecret);
        })
        .catch((err) => setError(err));
    } else setError("Amount is not set");
  }, [amount]);

  return (
    <>
      <paymentContext.Provider
        value={{ hasPaid, setHasPaid, paymentDetails, setPaymentDetails }}
      >
        <ErrorModal error={error} onClear={onHandleError} />

        <div id="signup-page">
          <Card className="signup-container">
            {isLoading && <LoadingSpinner asOverlay />}

            <h1>Sign Up</h1>
            {/* STEPPER */}
            <div className="signup_form__stepper">
              <FontAwesomeIcon
                className={formIndex >= 1 ? "signup_form__svg-circle" : ""}
                icon={faCircle}
              />
              <p
                className={`stepper_item${
                  formIndex >= 1 ? "" : "stepper_active"
                }`}
              >
                User Info
              </p>
              <FontAwesomeIcon
                className={formIndex >= 2 ? "signup_form__svg-circle" : ""}
                icon={formIndex >= 2 ? faCircle : farCircle}
              />

              <p
                className={`stepper_item${
                  formIndex >= 2 ? "" : "stepper_active"
                }`}
              >
                Choose Card
              </p>
              <FontAwesomeIcon
                className={formIndex >= 3 ? "signup_form__svg-circle" : ""}
                icon={formIndex >= 3 ? faCircle : farCircle}
              />
              <p
                className={`stepper_item${
                  formIndex >= 3 ? "" : "stepper_active"
                }`}
              >
                Pay
              </p>
            </div>
            <div className="stepper_instructions">
              {formIndex === 1 ? (
                <p>
                  Enter your signup information and hit 'Next' to select your
                  card.
                </p>
              ) : formIndex === 2 ? (
                <p>
                  Choose our Adapt logo card or use your own image. We recommend
                  this size for best results 3:2 ratio.
                </p>
              ) : (
                <p>Pay now to setup your profile and recieve your card.</p>
              )}
            </div>
            <Formik
              id="signup_form__formik"
              initialValues={initialValues}
              onSubmit={onSignupHandler}
              validationSchema={validationSchema}
            >
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <Form
                  className={`${
                    formIndex === 1 ? "signup-form_index-one" : "signup-form"
                  }`}
                  encType="multipart/form-data"
                  onSubmit={handleSubmit}
                >
                  <LogFormValues
                    setUsernameAvailable={setUsernameAvailable}
                    setEmailAvailable={setEmailAvailable}
                  />
                  {formIndex === 1 && (
                    <UserInfo
                      errors={errors}
                      touched={touched}
                      formIndex={formIndex}
                      setFormIndex={setFormIndex}
                      usernameAvailable={usernameAvailable}
                      emailAvailable={emailAvailable}
                      setFieldValue={setFieldValue}
                      setSelection={setSelection}
                    />
                  )}
                  {formIndex === 2 && (
                    <CardImageSelect
                      type={type}
                      cardImage={cardImage}
                      backCardImage={backCardImage}
                      user={user}
                      setCardImage={setCardImage}
                      setBackCardImage={setBackCardImage}
                      setFieldValue={setFieldValue}
                      //resetCardImage={resetCardImage}
                      formIndex={formIndex}
                      setFormIndex={setFormIndex}
                    />
                  )}

                  <button
                    id="submit-btn"
                    size="xl"
                    type="submit"
                    style={{ display: "none" }}
                  >
                    Submit Form
                  </button>
                  {error && <div id="payment-message">{error}</div>}
                </Form>
              )}
            </Formik>
            <div>
              {clientSecret && stripePromise && formIndex === 3 && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <PaymentForm
                    formIndex={formIndex}
                    setFormIndex={setFormIndex}
                    amount={amount}
                  />
                </Elements>
              )}
            </div>
          </Card>
        </div>
      </paymentContext.Provider>
    </>
  );
};

export default SignupForm;
