// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user_tile__container {
  width: 80%;
  margin: 5px auto;
}
`, "",{"version":3,"sources":["webpack://./src/super-admin/components/UserTile.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".user_tile__container {\n  width: 80%;\n  margin: 5px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
