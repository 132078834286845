// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update_card__contact-image {
  aspect-ratio: 3/2;
  width: 336px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #ccc;
  object-fit: cover;
  margin: auto auto;
}

#update_user_card-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card_replacement__stripe-payment {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card_replacement__instruction {
  width: 400px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.update_user_card__success {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  text-align: center;
  margin: 0 2rem;
}

.card_replacement__back-button {
  max-width: 400px;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/shared/forms/CardReplacement.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,4BAA4B;EAC5B,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".update_card__contact-image {\n  aspect-ratio: 3/2;\n  width: 336px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-shadow: 2px 2px 2px #ccc;\n  object-fit: cover;\n  margin: auto auto;\n}\n\n#update_user_card-page {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.card_replacement__stripe-payment {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.card_replacement__instruction {\n  width: 400px;\n  text-align: center;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.update_user_card__success {\n  height: 90vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 2rem;\n  text-align: center;\n  margin: 0 2rem;\n}\n\n.card_replacement__back-button {\n  max-width: 400px;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
