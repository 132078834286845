// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update_user-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 95vw;
  max-width: 400px;
}

#update_user-page {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80vh;
  overflow: scroll;
}

.update_user__theme_selection {
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #ccc;
}
.update_user__theme_choice img {
  height: 60px;
  width: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/shared/forms/UpdateUserInfoForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;EACrB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,8BAA8B;EAC9B,WAAW;AACb;AACA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".update_user-form {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-content: center;\n  width: 95vw;\n  max-width: 400px;\n}\n\n#update_user-page {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  height: 80vh;\n  overflow: scroll;\n}\n\n.update_user__theme_selection {\n  text-align: center;\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  color: #ccc;\n}\n.update_user__theme_choice img {\n  height: 60px;\n  width: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
