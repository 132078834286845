import React from "react";
import WavePath from "../../shared/img/wavePath.png";
import BackgroundImage from "../../shared/img/ArtistBackground.png";
import ProfileImage from "../../shared/img/ArtistProfile.png";
import ArtistLogo from "../../shared/img/AdaptLogo.png";
import FacebookIcon from "../../shared/img/facebookIcon.png";
import TwitterIcon from "../../shared/img/twitterIcon.png";
import InstagramIcon from "../../shared/img/instagramIcon.png";
import LinkedinIcon from "../../shared/img/linkedinIcon.png";
import PinterestIcon from "../../shared/img/pinterestIcon.png";
import YoutubeIcon from "../../shared/img/youtubeIcon.png";
import SnapchatIcon from "../../shared/img/snapchatIcon.png";
import WebsiteIcon from "../../shared/img/websiteIcon.png";

import "./Artist.css";
import ThemeFooter from "./components/ThemeFooter";

const Artist = ({ profileData, handleDownload, link }) => {
  return (
    <div id="artist_theme__container">
      <img
        src={`${process.env.REACT_APP_BACKEND_API}/${profileData.backgroundimage}`}
        className="artist_theme__background-img"
        alt="artist background example"
      />
      <img src={WavePath} className="artist_theme__wave" alt="" />
      <div className="artist_theme__profile-img-container">
        <img
          src={`${process.env.REACT_APP_BACKEND_API}/${profileData.profileimage}`}
          className="artist_theme__profile-img"
          alt=""
        />
      </div>
      <div className="artist_theme__info-section">
        <div className="artist_theme__info-section-left-column">
          <h3>
            {profileData.firstname} {profileData.lastname}
          </h3>
          {profileData.tagline !== null || "" ? (
            <h4>"{profileData.tagline}"</h4>
          ) : null}
          {profileData.title !== null || "" ? <p>{profileData.title}</p> : null}
          {profileData.location !== null || "" ? (
            <p>{profileData.location}</p>
          ) : null}
        </div>
        <div className="artist_theme__info-section-right-column">
          <img
            src={ArtistLogo}
            className="artist_theme__artist-logo-img"
            alt=""
          />
        </div>
      </div>
      <div className="artist_theme__contact-card">
        <div className="artist_theme__custom-button">
          <button>Book my services</button>
        </div>
        <div className="artist_theme__contact-button-container">
          <button>Add my contact</button>
          <button className="inverse">Share my contact</button>
        </div>
        <div className="artist_theme__social-links-container">
          <a
            href={"http://localhost:3000"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={WebsiteIcon}
              className="artist_theme__social-icon"
              alt=""
            />
          </a>
          <img
            src={FacebookIcon}
            className="artist_theme__social-icon"
            alt=""
          />
          <img src={TwitterIcon} className="artist_theme__social-icon" alt="" />
          <img
            src={InstagramIcon}
            className="artist_theme__social-icon"
            alt=""
          />
          <img
            src={LinkedinIcon}
            className="artist_theme__social-icon"
            alt=""
          />
          <img
            src={PinterestIcon}
            className="artist_theme__social-icon"
            alt=""
          />
          <img src={YoutubeIcon} className="artist_theme__social-icon" alt="" />
          <img
            src={SnapchatIcon}
            className="artist_theme__social-icon"
            alt=""
          />
        </div>
      </div>
      <div className="artist_theme__gallery-container">
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
        <img
          src={BackgroundImage}
          className="artist_theme__gallery-image"
          alt="gallery display"
        ></img>
      </div>
      {profileData.aboutme !== null || "" || undefined ? (
        <div className="artist_theme__about-container">
          <h3>About Me</h3>
          <p>{profileData.aboutme}</p>
        </div>
      ) : null}
      <ThemeFooter />
    </div>
  );
};

export default Artist;
