import React, { useEffect, useState } from "react";
import LogoImage from "../../shared/img/logo-img.png";
import AdaptLogo from "../../shared/img/adapt-background-placeholder.webp";
import ProfilePlaceholder from "../../shared/img/adapt-profile-placeholder.webp";
import "./Standard.css";
import StandardSocialLink from "./components/StandardSocialLink";
import axios from "axios";

const Standard = ({ profileData, handleDownload }) => {
  const [isSharingContact, setIsSharingContact] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [sentContact, setSentContact] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState(null);
  const [animate, setAnimate] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(null);
  const [fieldTouched, setFieldTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false
  });

  const [footerClass, setFooterClass] = useState(
    "standard_theme__sticky_bottom"
  );
  const [socialLinks, setSocialLinks] = useState(null);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    cardUserEmail: profileData ? profileData.email : ""
  });

  let hasSocials = false;

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleFieldBlur = (fieldName) => {
    setFieldTouched((prevTouched) => ({
      ...prevTouched,
      [fieldName]: true
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName || formData.firstName.length < 2) {
      errors.firstName = "First name must be at least 2 characters";
    }
    if (!formData.lastName || formData.lastName.length < 2) {
      errors.lastName = "Last name must be at least 2 characters";
    }
    if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Please enter a valid email";
    }
    if (
      !formData.phone ||
      !/^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/.test(
        formData.phone
      )
    ) {
      errors.phone = "Please enter a valid phone number";
    }

    // Check if there are no errors
    const isValid = Object.keys(errors).length === 0;
    setIsFormValid(isValid); // Set the isFormValid state
    setFormErrors(errors); // Set the formErrors state
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    validateForm();
    if (isFormValid) {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/api/email/send-tap-user-contact`,
          formData
        )
        .then((response) => {
          setSentContact(true);
          setIsSharingContact(false);
        })
        .catch((err) => {
          setError(err);
        });
    }
  };

  useEffect(() => {
    if (profileData) {
      if (profileData.redirectUrl) {
        window.location.replace(`https://${profileData.redirectUrl}`);
      }
      if (profileData.social !== undefined)
        setSocialLinks(Object.entries(profileData.social));
    }
    if (profileData.youtube) {
      var url = profileData.youtube;
      var id = url.split("?v=")[1];

      var embedlink = "http://www.youtube.com/embed/" + id;
      setYoutubeLink(embedlink);
    }
  }, []);

  useEffect(() => {
    if (socialLinks !== null || undefined) {
      setAnimate(true);
    } else {
    }

    const handleAnimation = () => {
      if (socialLinks && socialLinks.length > 0) {
        setAnimate(true);
      }
    };

    handleAnimation();

    return () => {
      setAnimate(false);
    };
  }, [socialLinks]);

  useEffect(() => {
    const handleScroll = () => {
      const footerElement = document.getElementById("standard_theme__footer");
      const footerPosition = footerElement.getBoundingClientRect();
      const isFooterAtBottom =
        footerPosition.top + footerPosition.height >= window.innerHeight;

      if (!isFooterAtBottom) {
        setFooterClass("standard_theme__sticky_bottom");
      } else {
        setFooterClass("standard_theme__footer");
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   const errors = validateForm();
  //   setIsFormValid(Object.keys(errors).length === 0);
  // }, [formData]);

  return (
    <>
      <div className="standard_theme__container">
        <div className="standard_theme__background-img">
          <img
            src={
              profileData.backgroundimage
                ? profileData.backgroundimage
                : AdaptLogo
            }
            alt=""
            style={{
              objectFit: "cover",
              maxWidth: "500px",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          />
        </div>
        <div className="standard_theme__hero">
          <div className="standard_theme__profile_pic">
            <img
              src={
                profileData.profileimage
                  ? profileData.profileimage
                  : ProfilePlaceholder
              }
              alt=""
            />
          </div>
          <div className="standard_theme__info">
            <h2>
              {profileData.firstname} {profileData.lastname}
            </h2>
            {profileData.tagline !== null || "" ? (
              <h3>{profileData.tagline}</h3>
            ) : null}
            {profileData.company !== null || "" ? (
              <p>{profileData.company}</p>
            ) : null}
            {profileData.title !== null || "" ? (
              <p>{profileData.title}</p>
            ) : null}
            {profileData.location !== null || "" ? (
              <p>{profileData.location}</p>
            ) : null}
            <hr />
            {profileData.aboutme !== null || "" || undefined ? (
              <div className="standard_theme__aboutme">
                <p>{profileData.aboutme}</p>
              </div>
            ) : null}
            {profileData.website !== null || "" ? (
              <a
                className="standard_theme__website_link"
                href={profileData.website}
              >
                {profileData.website}
              </a>
            ) : null}
          </div>
          {profileData.skills ? (
            <div className="standard_theme__skills">
              {profileData.skills.split(",").map((skill, index) => {
                return (
                  <div key={index} className="standard_theme__skill">
                    {skill}
                  </div>
                );
              })}{" "}
            </div>
          ) : null}
        </div>
        <button
          type="button"
          disabled={downloadComplete}
          className="standard_theme__download_contact_button"
          onClick={() => {
            handleDownload();
            setDownloadComplete(true);
          }}
        >
          {downloadComplete ? "Added Contact" : "Add Contact"}
        </button>
        {!isSharingContact && (
          <button
            type="button"
            className="standard_theme__share_contact_button"
            onClick={() => setIsSharingContact(true)}
          >
            {sentContact ? "Sent!" : "Share My Info"}
          </button>
        )}
        {/* {isSharingContact && ( */}
        <div
          className={`${
            isSharingContact
              ? "standard_theme__form"
              : "standard_theme__form_hidden"
          }`}
        >
          <form className="standard_theme__form" onSubmit={handleSubmit}>
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleOnChange}
              onBlur={() => handleFieldBlur("firstName")}
            />
            {formErrors.firstName && <p>{formErrors.firstName}</p>}

            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleOnChange}
              onBlur={() => handleFieldBlur("lastName")}
            />
            {formErrors.lastName && <p>{formErrors.lastName}</p>}

            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleOnChange}
              onBlur={() => handleFieldBlur("email")}
            />
            {formErrors.email && <p>{formErrors.email}</p>}

            <label>Phone Number:</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleOnChange}
              onBlur={() => handleFieldBlur("phone")}
            />
            {formErrors.phone && <p>{formErrors.phone}</p>}

            <button
              className="standard_theme__share_contact_button"
              type="submit"
            >
              Send
            </button>
          </form>
        </div>
        <div className="standard_theme__links_container">
          {socialLinks !== null || undefined ? (
            socialLinks.map(([platform, link], index) => {
              let newUrl = (url) => {
                if (!url.startsWith("https://") && !url.startsWith("http://")) {
                  return `https://${url}`;
                }
                return url;
              };
              return (
                <div
                  key={platform}
                  className={`social-link ${animate ? "animated" : ""}`}
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <StandardSocialLink
                    profileData={profileData}
                    platform={platform}
                    link={newUrl(link)}
                  />
                </div>
              );
            })
          ) : (
            <div className="standard_theme__no-links-yet">
              <h4>No Links, yet.</h4>
            </div>
          )}
        </div>

        {/**** YOUTUBE SECTION ****/}
        {/* {youtubeLink !== null || "" ? (
          <div className="standard_theme__card">
            <div className="youtube-container">
              <iframe
                id="myIframe"
                src={youtubeLink}
                title="youtube"
                width="560"
                height="315"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        ) : null} */}

        {/**** FOOTER SECTION ****/}
        <div id="standard_theme__footer" className={footerClass}>
          <h6>
            Powered by{"  "}
            <a href={process.env.REACT_APP_HOMEPAGE}>
              <img src={LogoImage} alt="powered by adapt" />
            </a>
          </h6>
        </div>
      </div>
    </>
  );
};

export default Standard;
