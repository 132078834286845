import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "../shared/UIElements/Card";
import UserTile from "./components/UserTile";
import ErrorModal from "../shared/UIElements/ErrorModal";

const AllUsers = ({ users, setActiveUser, getAllUsers, setForm }) => {
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState(null);
  const [userList, setUserList] = useState(users);

  const selectUser = (user) => {
    setActiveUser(user);
  };

  const handleSearchChange = (event) => {
    const text = event.target.value;
    setSearchText(text);
  };

  const deleteUser = (userId) => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_API}/api/user/delete/${userId}`)
      .then((response) => {
        // Remove the deleted user from the user list
        const updatedUserList = userList.filter((u) => u._id !== userId);
        setUserList(updatedUserList);
      })
      .catch((err) => {
        setError(err);
      });
  };

  const onHandleError = () => {
    setError(null);
  };

  // Filter users based on input text
  const filteredUsers = userList?.filter(
    (user) =>
      user.firstname?.toLowerCase().includes(searchText.toLowerCase()) ||
      user.lastname?.toLowerCase().includes(searchText.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <ErrorModal error={error} onClear={onHandleError} />
      <div className="super_admin__users_container">
        <div className="super_admin__search_bar_container">
          <form onChange={handleSearchChange} id="super_admin__search_bar_form">
            <h3>Search</h3>
            <input
              className="super_admin__search_bar_container_input"
              type="text"
            />
          </form>
        </div>
        {filteredUsers ? (
          searchText === "" ? (
            // If no search text, display all users
            userList.map((user, index) => (
              <UserTile
                key={index}
                user={user}
                deleteUser={() => deleteUser(user._id)}
                selectUser={selectUser}
                setForm={setForm}
              />
            ))
          ) : filteredUsers && filteredUsers.length > 0 ? (
            // If there are matches, display UserTile components
            filteredUsers.map((user, index) => (
              <UserTile
                key={index}
                user={user}
                deleteUser={() => deleteUser(user)}
                selectUser={selectUser}
                setForm={setForm}
              />
            ))
          ) : (
            // If no matches, display "No matches found" message
            <p>No matches found.</p>
          )
        ) : null}
      </div>
    </>
  );
};

export default AllUsers;
