// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset_password__container {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset_password__form-container {
  width: 450px;
  padding: 50px 20px;
  /* margin: auto auto; */
  margin: 30px 20px;
}

.reset_password__visibility-button {
  background-color: none;
  border: none;
  background: none;
}

.reset_password__form-container input {
  width: 70%;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/shared/forms/ResetPassword.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB","sourcesContent":[".reset_password__container {\n  width: 100%;\n  height: 90vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.reset_password__form-container {\n  width: 450px;\n  padding: 50px 20px;\n  /* margin: auto auto; */\n  margin: 30px 20px;\n}\n\n.reset_password__visibility-button {\n  background-color: none;\n  border: none;\n  background: none;\n}\n\n.reset_password__form-container input {\n  width: 70%;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
