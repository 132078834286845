import React from "react";

import "./About.css";

const About = () => {
  return (
    <div>
      <div className="about__introduction">
        <div className="about__welcome">
          <h1>Welcome to Adapt Card At Adapt Card</h1>
          <p>
            we believe that every first-time interaction or business card
            exchange is an opportunity to make a lasting impression. That's why
            we've developed cutting-edge NFC tap business cards that
            revolutionize the way you connect and engage with others.
          </p>
        </div>
        <div className="about__network">
          <h1>Elevate Your Networking Game</h1>
          <p>
            Our NFC tap business cards are designed to maximize every networking
            opportunity. With a simple tap, you can provide the recipient with
            quick and easy access to your contact information and online
            presence. Say goodbye to fumbling for traditional paper business
            cards – Adapt Card streamlines the process and ensures your details
            are effortlessly shared.
          </p>
        </div>
        <div className="about__trust">
          <h1>Building Trust from the First</h1>
          <p>
            Tap We understand the importance of trust in business relationships.
            By utilizing NFC technology, our cards go beyond just contact
            information. They validate your identity and authenticity, instantly
            establishing trust with potential clients, partners, and contacts.
            With Adapt Card, you make an immediate and powerful impression that
            sets you apart from the competition.
          </p>
        </div>
        <div className="about__standout">
          <h1>Standing Out Above the Rest</h1>
          <p>
            In today's fast-paced world, standing out is key to success. Our NFC
            tap business cards give you a unique edge, making you memorable in a
            sea of traditional cards. By showcasing your tech-savvy approach,
            you position yourself as innovative and forward-thinking.
          </p>
        </div>
        <div className="about__convenience">
          <h1>Convenience Redefined</h1>
          <p>
            Being easy to work with is a valuable asset. Adapt Card helps you
            showcase your convenience and efficiency right from the first
            interaction. With just a tap, you effortlessly share your
            information, making it a breeze for clients and contacts to get in
            touch with you.
          </p>
        </div>
        <div className="about__customized">
          <h1>Customized and Branded Profiles</h1>
          <p>
            At Adapt Card, we understand that every business has its specific
            processes and requirements. That's why we offer not only
            professional and impressive standard theme profiles but also
            completely custom and branded profiles. If you're part of a team
            like a brokerage and want all your realtors to have a uniform
            landing page experience, our custom profiles make it possible.
            Tailor your landing pages to match your brand identity, creating a
            seamless and cohesive experience for your team members and clients.
          </p>
        </div>
        <div className="about__business_process">
          <h1>Streamlined Business Processes</h1>
          <p>
            Our completely custom and branded profiles are designed to assist
            your team members in seamlessly moving their interactions to the
            next steps in your specific processes. Whether it's lead generation,
            client onboarding, or any other business workflow, Adapt Card's
            personalized profiles help you optimize your operations.
          </p>
        </div>
        <div className="about__join_us">
          <h1>Join the NFC Revolution</h1>
          <p>
            Adapt Card is at the forefront of the NFC revolution. Say goodbye to
            outdated paper business cards and embrace the future of networking.
            Our user-friendly and sustainable solution makes networking more
            efficient and impactful than ever before.
          </p>
        </div>
        <div className="about__experience">
          <h1>Experience the Power of Adapt Card</h1>
          <p>
            Try Adapt Card today and unlock the potential of your first-time
            interactions. Join thousands of professionals who have already
            enhanced their networking game with our innovative NFC tap business
            cards.
          </p>
        </div>
        <div className="about__call_to_action">
          <h1>
            Ready to make a memorable impression and build lasting connections?
            Tap into the future with Adapt Card.
          </h1>
          ** --- Feel free to further customize this content to align with your
          company's branding and messaging. Highlighting the ability to have
          both standard themes and fully customized profiles emphasizes Adapt
          Card's flexibility and versatility, making it an even more attractive
          solution for businesses and teams with unique branding and workflow
          needs.
        </div>
      </div>
    </div>
  );
};

export default About;
