// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule_form__page {
  height: 90vh;
}

.schedule__container {
  margin-top: 50px;
  padding: 20px;
}
.schedule__container h1 {
  margin-top: 20px;
}
.schedule__info {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/home/ScheduleCustomCreation.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".schedule_form__page {\n  height: 90vh;\n}\n\n.schedule__container {\n  margin-top: 50px;\n  padding: 20px;\n}\n.schedule__container h1 {\n  margin-top: 20px;\n}\n.schedule__info {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
