import React, { useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { AuthContext } from "../context/auth-context";

import "./NavLinks.css";

const NavLinks = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const logoutHandler = () => {
    auth.logout();
    navigate("/");
  };

  return (
    <div>
      <ul className="nav-links">
        {!auth.isLoggedIn && (
          <li>
            <NavLink onClick={handleScrollToTop} to="/signup">
              Signup
            </NavLink>
          </li>
        )}
        {!auth.isLoggedIn && (
          <li>
            <NavLink to="/login">Login</NavLink>
          </li>
        )}
        {auth.isLoggedIn && (
          <li>
            <NavLink to={`/admin/${auth.userId}`}>Admin</NavLink>
          </li>
        )}
        <li>
          <NavLink to="/contact">Contact Us</NavLink>
        </li>
        {auth.isLoggedIn && (
          <li>
            <button onClick={logoutHandler}>LOGOUT</button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default NavLinks;
