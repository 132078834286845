// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #5fbcca;
  border-radius: 4px;
  background: #5fbcca;
  color: white;
  cursor: pointer;
  /* margin-right: 1rem; */
  text-decoration: none;
  display: inline-block;
  margin: 10px 0;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  border-color: #5fbcca;
  font-weight: bold;
}

.button--inverse {
  background: transparent;
  color: #5fbcca;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #5fbcca;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #5fbcca;
  border-color: #5fbcca;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

.button--xl {
  padding: 1rem 2rem;
  font-size: 2rem;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/shared/formElements/Button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,wBAAwB;EACxB,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,cAAc;AAChB;;AAEA;;EAEE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;;EAEE,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;;;EAGE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".button {\n  font: inherit;\n  padding: 0.5rem 1.5rem;\n  border: 1px solid #5fbcca;\n  border-radius: 4px;\n  background: #5fbcca;\n  color: white;\n  cursor: pointer;\n  /* margin-right: 1rem; */\n  text-decoration: none;\n  display: inline-block;\n  margin: 10px 0;\n}\n\n.button:focus {\n  outline: none;\n}\n\n.button:hover,\n.button:active {\n  border-color: #5fbcca;\n  font-weight: bold;\n}\n\n.button--inverse {\n  background: transparent;\n  color: #5fbcca;\n}\n\n.button--inverse:hover,\n.button--inverse:active {\n  color: white;\n  background: #5fbcca;\n}\n\n.button--danger {\n  background: #830000;\n  border-color: #830000;\n}\n\n.button--danger:hover,\n.button--danger:active {\n  background: #5fbcca;\n  border-color: #5fbcca;\n}\n\n.button:disabled,\n.button:hover:disabled,\n.button:active:disabled {\n  background: #ccc;\n  color: #979797;\n  border-color: #ccc;\n  cursor: not-allowed;\n}\n\n.button--small {\n  font-size: 0.8rem;\n}\n\n.button--big {\n  font-size: 1.5rem;\n}\n\n.button--xl {\n  padding: 1rem 2rem;\n  font-size: 2rem;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
