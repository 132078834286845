import React from "react";
import Button from "../../formElements/Button";
import { Field } from "formik";

import SignupForm from "../SignupForm";

const UserInfo = ({
  touched,
  errors,
  formIndex,
  setFormIndex,
  usernameAvailable,
  emailAvailable,
  setFieldValue,
  setSelection
}) => {
  return (
    <>
      <Field id="firstname" name="firstname" placeholder="first name" />
      {touched.firstname && errors.firstname && (
        <div className="error">{errors.firstname}</div>
      )}

      <Field id="lastname" name="lastname" placeholder="last name" />
      {touched.lastname && errors.lastname && (
        <div className="error">{errors.lastname}</div>
      )}

      <Field
        id="username"
        name="username"
        placeholder="username"
        onChange={(event) => {
          setFieldValue("username", event.target.value.trim().toLowerCase());
        }}
      />
      {touched.username && errors.username && (
        <div className="error">{errors.username}</div>
      )}
      {usernameAvailable !== null || "" ? (
        usernameAvailable === true ? null : (
          <p id="signup_form__availability">Username Not Available</p>
        )
      ) : null}

      <Field id="email" name="email" placeholder="email" />
      {touched.email && errors.email && (
        <div className="error">{errors.email}</div>
      )}
      {emailAvailable !== null || "" ? (
        emailAvailable === true ? null : (
          <p id="signup_form__availability">Email Not Available</p>
        )
      ) : null}

      <Field
        id="password"
        name="password"
        type="password"
        placeholder="password"
      />
      {touched.password && errors.password && (
        <div className="error">{errors.password}</div>
      )}

      <div className="next_button">
        <Button
          onClick={() => {
            setSelection(false);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Back
        </Button>
        {!errors.firstname &&
          touched.firstname &&
          !errors.lastname &&
          !errors.username &&
          !errors.email &&
          !errors.password &&
          usernameAvailable === true &&
          emailAvailable === true && (
            <Button
              onClick={() => {
                setFormIndex(formIndex + 1);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Next
            </Button>
          )}
      </div>
    </>
  );
};

export default UserInfo;
