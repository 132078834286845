import React, { useState, useEffect } from "react";
import SignupForm from "../shared/forms/SignupForm";
import StandardCardImage from "../shared/img/StandardImageCard.JPG";
import CustomStandardImageCard from "../shared/img/CustomStandardImageCard.jpg";
import StandardMetalCard from "../shared/img/StandardMetalCard.JPG";
import CustomMetalCard from "../shared/img/CustomMetalCard.JPG";
import Video from "../shared/img/0001-0140.mp4";

import "./Signup.css";
import Card from "../shared/UIElements/Card";
import Button from "../shared/formElements/Button";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const navigate = useNavigate();
  const [type, setType] = useState();
  const [amount, setAmount] = useState();
  const [selection, setSelection] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const videoSources = [Video, Video, Video];

  const handleSelection = (charge) => {
    setSelection(true);
    setAmount(charge);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCustomSelection = () => {
    navigate("/schedule-custom-creation");
  };

  return (
    <>
      {selection ? (
        <SignupForm type={type} amount={amount} setSelection={setSelection} />
      ) : (
        <div className="signup_form__selection">
          <div className="signup_form__custom_selection_option">
            <video
              id="videoPlayer"
              autoPlay
              loop
              preload="auto"
              className="video"
            >
              <source src={Video} type="video/mp4" />
            </video>
            <h3>Completely Custom Branded Designs</h3>
            <p>
              Branding is King! If you want a truly custom experience or uniform
              branding experience from everyone on your team, schedule a time to
              begin the discovery and design process.{" "}
            </p>
            <Button onClick={handleCustomSelection}>
              Schedule Appointment
            </Button>
          </div>
          <h3 className="signup_form__standard_h3">Standard Profile Cards</h3>
          <div className="signup_form__price_cards">
            <div className="signup_form__selection_option">
              <img src={StandardCardImage} alt="" />
              <h3>Adapt Card</h3>
              <p>NFC PVC Adapt Logo Card</p>
              <h4 className="signup_form__price_h4">$40</h4>
              <Button
                onClick={() => {
                  setType("StandardPVC");
                  handleSelection({ type: "StandardPVC", amount: 4000 });
                }}
              >
                Select
              </Button>
            </div>
            <div className="signup_form__selection_option">
              <img src={CustomStandardImageCard} alt="" />
              <h3>Custom Adapt Card</h3>
              <p>NFC PVC Custom Image Card</p>
              <h4 className="signup_form__price_h4">$60</h4>
              <Button
                onClick={() => {
                  setType("CustomPVC");
                  handleSelection({ amount: 6000 });
                }}
              >
                Select
              </Button>
            </div>
            <div className="signup_form__selection_option">
              <img src={StandardMetalCard} alt="" />
              <h3>Metal Adapt Card</h3>
              <p>NFC Metal Adapt Engraved Card</p>
              <h4 className="signup_form__price_h4">$90</h4>
              <Button
                onClick={() => {
                  setType("StandardMetal");
                  handleSelection({ amount: 9000 });
                }}
              >
                Select
              </Button>
            </div>
            <div className="signup_form__selection_option">
              <img src={CustomMetalCard} alt="" />
              <h3>Metal Custom Card</h3>
              <p>NFC Metal Custom Engraved Card</p>
              <h4 className="signup_form__price_h4">$110</h4>
              <Button
                onClick={() => {
                  setType("CustomMetal");
                  handleSelection({ amount: 11000 });
                }}
              >
                Select
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
