import React from "react";
import Logo from "../img/logo-img.png";
import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer__body">
        <img src={Logo} className="footer__logo-img" alt="adapt-logo" />
        <div className="footer__links">
          <Link className="footer__link" to="/terms-and-conditions">
            Terms and Conditions
          </Link>
          <Link className="footer__link" to="/privacy-policy">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
