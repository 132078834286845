import React, { useState } from "react";
import axios from "axios";
import Card from "../UIElements/Card";
import Button from "../formElements/Button";

import "./RequestNewPassword.css";
import ErrorModal from "../UIElements/ErrorModal";

const RequestNewPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const handleReset = async () => {
    await axios
      .post(`${process.env.REACT_APP_BACKEND_API}/api/user/request-password/`, {
        email: email
      })
      .then()
      .catch((err) => {
        setError("error: ", err);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const onHandleError = () => {
    setError(null);
  };
  return (
    <>
      <ErrorModal error={error} onClear={onHandleError} />
      <div className="reset_password__container">
        <div className="reset_password__form-container">
          <Card>
            <h3>Reset Password Email</h3>
            <form onSubmit={handleReset}>
              <input
                type="text"
                placeholder="email"
                value={email}
                onChange={handleChange}
              />
              <Button type="submit">Submit</Button>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default RequestNewPassword;
