import React, { useState } from "react";
import Button from "../formElements/Button";
import Card from "../UIElements/Card";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./ResetPassword.css";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ErrorModal from "../UIElements/ErrorModal";

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState();
  const [success, setSuccess] = useState(false);
  const [matchError, setMatchError] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const validate = () => {
    password === confirmPassword
      ? setPasswordsMatch(true)
      : setPasswordsMatch(false);

    passwordsMatch ? setMatchError(false) : setMatchError(true);
  };

  const onHandleError = () => {
    setError(null);
  };

  const handleReset = async (e) => {
    e.preventDefault();

    validate();

    if (passwordsMatch) {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/api/user/reset-password/${token}`,
          {
            newPassword: password,
            confirmPassword: confirmPassword
          }
        )
        .then((response) => {
          setSuccess(true);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <ErrorModal error={error} onClear={onHandleError} />
      <div className="reset_password__container">
        <div className="reset_password__form-container">
          <Card>
            <h3>Enter New Passwords</h3>
            <form onSubmit={handleReset}>
              <input
                name="password"
                type={passwordVisible ? "text" : "password"}
                placeholder="new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="reset_password__visibility-button"
                type="button"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                <FontAwesomeIcon
                  className="reset_password__icon"
                  icon={!passwordVisible ? faEye : faEyeSlash}
                  size="2x"
                />
              </button>
              <input
                name="confirm-password"
                type={passwordVisible ? "text" : "password"}
                placeholder="confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {matchError && !passwordsMatch ? (
                <p>Passwords do not match</p>
              ) : null}
              <Button type="submit">
                {success ? "Request Sent" : "Submit"}
              </Button>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
