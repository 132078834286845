import React, { useEffect, useState } from "react";
import axios from "axios";
import OrderTile from "./components/OrderTile";

import "./AllOrders.css";
import ErrorModal from "../shared/UIElements/ErrorModal";

const AllOrders = ({ setForm }) => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);

  const handleOrderComplete = (completedOrder) => {
    // Use filter to create a new array without the completed order
    const updatedOrders = orders.filter(
      (order) => order.ordernumber !== completedOrder.ordernumber
    );
    setOrders(updatedOrders);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/api/order/get-all-orders`)
      .then((response) => {
        setOrders(response.data.orders);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [orders]);

  const onHandleError = () => {
    setError(null);
  };

  return (
    <>
      <ErrorModal error={error} onClear={onHandleError} />
      <div className="super_admin__users_container">
        {orders.length > 0 ? (
          orders.map((order, index) => (
            <OrderTile
              key={index}
              order={order}
              setForm={setForm}
              onComplete={() => handleOrderComplete(order)}
            />
          ))
        ) : (
          <p>No orders found.</p>
        )}
      </div>
    </>
  );
};

export default AllOrders;
