import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainHeader from "./MainHeader";
import NavLinks from "./NavLinks";
import SideDrawer from "./SideDrawer";
import { Outlet } from "react-router";
import Logo from "../img/logo-img.png";
import Backdrop from "../UIElements/Backdrop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";

import "./MainNavigation.css";
const MainNavigation = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };
  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      {drawerIsOpen && (
        <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
          <nav className="main-navigation__drawer-nav">
            <NavLinks />
          </nav>
        </SideDrawer>
      )}
      <MainHeader>
        <div className="main-navigation__container">
          <button
            className="main-navigation__menu-btn"
            onClick={openDrawerHandler}
          >
            <FontAwesomeIcon icon={faBars} inverse size="3x" />
            {/* <span />
          <span />
          <span /> */}
          </button>
          <h1 className="main-navigation__title">
            <Link to="">
              <img src={Logo} className="main-navigation__logo" alt="logo" />
            </Link>
          </h1>
          <nav className="main-navigation__header-nav">
            <NavLinks />
          </nav>
        </div>
      </MainHeader>
      <Outlet />
      <Footer />
    </>
  );
};

export default MainNavigation;
