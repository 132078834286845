// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme_footer__container {
  height: 80px;
  text-align: center;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme_footer__link {
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

.theme_footer__logo {
  height: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/user/themes/components/ThemeFooter.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".theme_footer__container {\n  height: 80px;\n  text-align: center;\n  background-color: #000;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.theme_footer__link {\n  width: 100%;\n  height: 100%;\n  text-decoration: none;\n  color: inherit;\n}\n\n.theme_footer__logo {\n  height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
