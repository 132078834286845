import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../../shared/formElements/Button";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import Card from "../../shared/UIElements/Card";
import { AuthContext } from "../../shared/context/auth-context";
import ErrorModal from "../../shared/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/UIElements/LoadingSpinner";

import UserIcon from "../../shared/img/UserIcon.png";
// import UserIcon from "../../shared/img/Group1.svg";
import RealtorIcon from "../../shared/img/RealtorIcon.png";
import ArtistIcon from "../../shared/img/ArtistIcon.png";
import UserIconOption from "../../shared/img/EntrepreneurIconOption.png";
import RealtorIconOption from "../../shared/img/RealtorIconOption.png";
import ArtistIconOption from "../../shared/img/ArtistIconOption.png";

import "./UpdateUserInfoForm.css";

const UpdateUserInfoForm = ({ setTheme }) => {
  const auth = useContext(AuthContext);

  const [userInfo, setUserInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saved, setSaved] = useState(false);
  const [buttonText, setButtonText] = useState("Update User");
  const [selectedTheme, setSelectedTheme] = useState("Standard");

  const handleThemeChange = (theme) => {
    const themeData = JSON.stringify({ theme: theme });
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_API}/api/user/update-theme/${auth.userId}`,
        themeData,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then((response) => {
        setSelectedTheme(theme);
        setTheme(theme);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  //values for formik
  const initialValues = {
    firstname: userInfo ? (userInfo.firstname ? userInfo.firstname : "") : "",
    lastname: userInfo ? (userInfo.lastname ? userInfo.lastname : "") : "",
    email: userInfo ? (userInfo.email ? userInfo.email : "") : ""
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required("REQUIRED"),
    lastname: Yup.string().required("REQUIRED"),
    email: Yup.string().email("Invalid email format.").required("REQUIRED")
  });

  //handle button text
  const updateSubmitButtonText = () => {
    setButtonText("Saving...");
    setTimeout(() => {
      setButtonText("Saved");
      setSaved(true);
    }, 2000);
  };

  const updateUser = async (values) => {
    setIsLoading(true);

    const updateValues = JSON.stringify(values);

    setIsLoading(true);
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_API}/api/user/update/${auth.userId}`,
        updateValues,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          }
        }
      )
      .then((response) => {
        setUserInfo(response.data.user);
        updateSubmitButtonText();
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response.data.message);
        throw new Error(err.response.data.message);
      });
    setIsLoading(false);
  };

  const onUpdateSubmit = async (values) => {
    updateUser(values);
  };

  const editModeHandler = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };

  const onHandleError = () => {
    setError(null);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/user/find-user/${auth.userId}`
      )
      .then((response) => {
        setUserInfo(response.data.user);
        setSelectedTheme(response.data.user.theme);
        setTheme(response.data.user.theme);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  useEffect(() => {
    if (saved)
      setTimeout(() => {
        setButtonText("Update User");
        setSaved(false);
      }, 2000);
  }, [saved]);

  if (userInfo) {
    return (
      <>
        <ErrorModal error={error} onClear={onHandleError} />
        <div id="update_user-page">
          <div className="update_user-container">
            {isLoading && <LoadingSpinner asOverlay />}
            {/* <h1>Update Theme</h1>
            <div className="update_user__theme_selection">
              <div
                className="update_user__theme_choice"
                onClick={() => handleThemeChange("Standard")}
              >
                <img
                  src={selectedTheme === "Standard" ? UserIcon : UserIconOption}
                  alt=""
                />
                <p>Entreprenuer</p>
              </div>
              <div
                className="update_user__theme_choice"
                onClick={() => handleThemeChange("Realtor")}
              >
                <img
                  src={
                    selectedTheme === "Realtor"
                      ? RealtorIcon
                      : RealtorIconOption
                  }
                  alt=""
                />
                <p>Realtor</p>
              </div>
              <div
                className="update_user__theme_choice"
                onClick={() => handleThemeChange("Artist")}
              >
                <img
                  src={
                    selectedTheme === "Artist" ? ArtistIcon : ArtistIconOption
                  }
                  alt=""
                />
                <p>Artist</p>
              </div>
            </div> */}
            <h1>Update User Info</h1>
            <Formik
              initialValues={initialValues}
              onSubmit={onUpdateSubmit}
              validationSchema={validationSchema}
            >
              <Form className="update_user-form">
                <label>First Name</label>
                <Field
                  id="firstname"
                  name="firstname"
                  type="firstname"
                  placeholder="first name"
                />
                <label>Last Name</label>

                <Field
                  id="lastname"
                  name="lastname"
                  type="lastname"
                  placeholder="last name"
                />
                <label>Email</label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  placeholder="email"
                />
                <Button
                  size="l"
                  type="button"
                  onClick={editModeHandler}
                  inverse
                >
                  Cancel
                </Button>
                <Button size="l" type="submit">
                  {buttonText}
                </Button>
              </Form>
            </Formik>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="">
        <LoadingSpinner />
      </div>
    );
  }
};

export default UpdateUserInfoForm;
