import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "../../shared/UIElements/Card";
import "./OrderTile.css";
import ViewOrder from "./ViewOrder";
import Button from "../../shared/formElements/Button";
import ErrorModal from "../../shared/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/UIElements/LoadingSpinner";
import StockImage from "../../shared/img/AdaptLogo.png";
import StockBackImage from "../../shared/img/back-logo-img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { setLocale } from "yup";

const OrderTile = ({ order, setForm, onComplete }) => {
  const [userData, setUserData] = useState({});
  const [cardImage, setCardImage] = useState(StockImage);
  const [backCardImage, setBackCardImage] = useState(StockBackImage);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const downloadImage = (imageUrl, fileName) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImageError = (imageType) => {
    if (imageType === "cardImage") {
      setCardImage(
        `${process.env.REACT_APP_BACKEND_API}/uploads/images/logo-img.png`
      );
    } else if (imageType === "backCardImage") {
      setBackCardImage(
        `${process.env.REACT_APP_BACKEND_API}/uploads/images/logo-img.png`
      );
    }
  };

  const completeOrder = async () => {
    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/order/complete-order/${order.ordernumber}`
      )
      .then((response) => {
        setIsLoading(false);
        onComplete();
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
        setError(err.response.data.message);
        throw new Error(err.response.data.message);
      });
  };

  const onHandleError = () => {
    setError(null);
  };

  useEffect(() => {
    if (order) {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_API}/api/user/find-user/${order?.userId}`
        )
        .then((response) => {
          setUserData(response.data.user);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setCardImage(userData.cardimage);
      setBackCardImage(userData.backcardimage);
    }
  }, [userData]);

  return (
    <>
      <ErrorModal error={error} onClear={onHandleError} />
      <div className="user_tile__container">
        <Card>
          {isLoading && <LoadingSpinner asOverlay />}
          <div id="order_tile__container" className="order_tile__container">
            <div className="order_tile__card-container">
              <img src={cardImage} alt="" className="order_tile__cardimage" />
              <img
                src={backCardImage}
                alt=""
                className="order_tile__cardimage"
              />
            </div>
            <div className="order_tile__info">
              <p>{order.name}</p>
              <p>{order.shippinglineone}</p>
              <p>{order.shippinglinetwo}</p>
              <p>
                {order.city}, {order.state} {order.zipcode}
              </p>
              <p>{order.ordernumber}</p>
            </div>
          </div>
          <div className="order_tile__button_container">
            <Button
              className="order_tile__button"
              onClick={() => {
                downloadImage(
                  `${
                    process.env.REACT_APP_BACKEND_API
                  }/api/user/download/${userData.cardimage.replace(
                    "uploads/images/",
                    ""
                  )}`
                );
              }}
            >
              <FontAwesomeIcon
                icon={faFileArrowDown}
                className="order_tile__icon"
              />
              Front
            </Button>
            <Button
              jazz="order_tile__button"
              onClick={() => {
                downloadImage(
                  `${
                    process.env.REACT_APP_BACKEND_API
                  }/api/user/download/${userData.backcardimage.replace(
                    "uploads/images/",
                    ""
                  )}`
                );
              }}
            >
              <FontAwesomeIcon
                icon={faFileArrowDown}
                className="order_tile__icon"
              />
              Back
            </Button>
            <Button
              jazz="order_tile__button"
              onClick={() =>
                setForm(
                  <ViewOrder
                    order={order}
                    cardImage={cardImage}
                    backCardImage={backCardImage}
                    setForm={setForm}
                  />
                )
              }
            >
              View
            </Button>
            <Button jazz="order_tile__button" onClick={completeOrder}>
              Complete
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default OrderTile;
