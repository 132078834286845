import React, { useState, useEffect } from "react";
import ErrorModal from "../../shared/UIElements/ErrorModal";
import axios from "axios";

import "./SuperAdmin.css";
import UserTile from "../../super-admin/components/UserTile";
import AllUsers from "../../super-admin/AllUsers";
import AllOrders from "../../super-admin/AllOrders";
import AllCompletedOrders from "../../super-admin/AllCompletedOrders";

const SuperAdmin = () => {
  const [users, setUsers] = useState();
  const [activeUser, setActiveUser] = useState();
  const [error, setError] = useState(null);
  const [activeButton, setActiveButton] = useState();
  const [form, setForm] = useState();

  const handleError = () => {
    setError(null);
  };

  const getAllUsers = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/api/user/get-all-users`)
      .then((response) => {
        setUsers(response.data.users);
        setForm(<AllUsers users={response.data.users} />);
      })
      .catch((err) => {
        setError(err);
      });
  };

  //gett all users and refresh when updated
  useEffect(() => {
    getAllUsers();
    //setForm(<AllUsers users={users} />);
    setActiveButton("UserInfo");
  }, []);

  return (
    <>
      <ErrorModal error={error} onClear={handleError}></ErrorModal>
      <div className="admin__container">
        <div className="admin__form-selection">
          <ul>
            <li
              className={
                activeButton === "UserInfo" ? "admin_form_active-btn" : ""
              }
              onClick={() => {
                setForm(
                  <AllUsers
                    users={users}
                    setActiveUser={setActiveUser}
                    getAllUsers={getAllUsers}
                    setForm={setForm}
                  />
                );
                setActiveButton("UserInfo");
              }}
            >
              All Users
            </li>

            <li
              className={
                activeButton === "ProfileInfo" ? "admin_form_active-btn" : ""
              }
              onClick={() => {
                setForm(<AllOrders setForm={setForm} />);
                setActiveButton("ProfileInfo");
              }}
            >
              Orders
            </li>

            <li
              className={
                activeButton === "ContactCardInfo"
                  ? "admin_form_active-btn"
                  : ""
              }
              onClick={() => {
                setForm(<AllCompletedOrders />);
                setActiveButton("ContactCardInfo");
              }}
            >
              Completed Orders
            </li>
          </ul>
          {activeUser ? <p>{activeUser.username}</p> : null}
        </div>
        <div className="admin__form-selected">
          <div>{users ? form : null}</div>
        </div>
      </div>
    </>
  );
};

export default SuperAdmin;
