import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ErrorModal from "../../shared/UIElements/ErrorModal";
import WarningModal from "../../shared/UIElements/WarningModal";
import UpdateProfileForm from "../../shared/forms/UpdateProfileForm";
import UpdateUserInfoForm from "../../shared/forms/UpdateUserInfoForm";
import UpdateVCardForm from "../../shared/forms/UpdateVCardForm";
import ArrowIcon from "../../shared/img/arrowIcon.png";
import { Link, useNavigate } from "react-router-dom";

import { AuthContext } from "../../shared/context/auth-context";

import "./UserAdmin.css";
import Button from "../../shared/formElements/Button";

const UserAdmin = () => {
  const auth = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState(null);
  const [activeButton, setActiveButton] = useState("UserInfo");
  const [theme, setTheme] = useState("Standard");
  const [warning, setWarning] = useState(false);
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [form, setForm] = useState(<UpdateUserInfoForm setTheme={setTheme} />);

  const handleError = () => {
    setError(null);
    setWarning(null);
  };

  const deleteAccount = async () => {
    await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_API}/api/user/delete/${auth.userId}`
      )
      .then((response) => {
        alert("Account deleted successfully.");
        auth.logout();
        navigate("/");
      })
      .catch((err) => {
        console.log("Error deleting account: ", err.message);
      });
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/user/find-user/${auth.userId}`
      )
      .then((response) => {
        setUserInfo(response.data.user);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [auth.userId]);

  return (
    <>
      <ErrorModal error={error} onClear={handleError}></ErrorModal>
      <WarningModal
        warning={warning}
        clear={handleError}
        onClear={handleError}
        deleteAccount={deleteAccount}
      >
        Are you you want to cancel your account?
      </WarningModal>
      <div className="admin__container">
        <div className="admin__form-selection">
          <div className="user_admin__top">
            <ul>
              <li
                className={
                  activeButton === "UserInfo" ? "admin_form_active-btn" : ""
                }
                onClick={() => {
                  window.scrollTo(0, 0);
                  setForm(<UpdateUserInfoForm setTheme={setTheme} />);
                  setActiveButton("UserInfo");
                }}
              >
                User Info
              </li>

              <li
                className={
                  activeButton === "ProfileInfo" ? "admin_form_active-btn" : ""
                }
                onClick={() => {
                  window.scrollTo(0, 0);
                  setForm(<UpdateProfileForm theme={theme} />);
                  setActiveButton("ProfileInfo");
                }}
              >
                Profile Info
              </li>

              <li
                className={
                  activeButton === "ContactCardInfo"
                    ? "admin_form_active-btn"
                    : ""
                }
                onClick={() => {
                  window.scrollTo(0, 0);
                  setForm(<UpdateVCardForm />);
                  setActiveButton("ContactCardInfo");
                }}
              >
                Contact Card Info
              </li>
            </ul>
            {userInfo ? (
              <div className="user_admin-view-profile-button">
                <Button to={`/profile/${userInfo?.username}`} size="big">
                  View Profile
                </Button>
              </div>
            ) : null}
            {userInfo ? (
              <div className="user_admin-view-profile-button">
                <Button
                  to={`/card-replacement/${auth.userId}`}
                  size="big"
                  inverse
                >
                  Replace Card
                </Button>
              </div>
            ) : null}
          </div>
          <Link
            className="user_admin__delete-profile-button"
            onClear={handleError}
            clear={handleError}
            warning={warning}
            deleteAccount={deleteAccount}
            size="big"
            onClick={() => setWarning(true)}
          >
            Delete Account
          </Link>
          {/* </div> */}
        </div>
        <div className="admin__form-selected">{form}</div>
      </div>
      <div className="admin_form__delete-button">
        <Link
          className="user_admin__delete-profile-button-bottom"
          onClear={handleError}
          clear={handleError}
          warning={warning}
          deleteAccount={deleteAccount}
          size="big"
          onClick={() => setWarning(true)}
        >
          Delete Account
        </Link>
      </div>
    </>
  );
};

export default UserAdmin;
