// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer__body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
}

.footer__links {
  display: flex;
}
.footer__link {
  margin: auto 2rem;
  text-decoration: none;
  color: white;
}

.footer__logo-img {
  height: 2rem;
  padding: 2rem;
}

@media only screen and (max-width: 500px) {
  .footer__body {
    display: inline;
    text-align: center;
  }
  .footer__links {
    flex-direction: column;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .footer__link {
    margin: 3px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/navigation/Footer.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,WAAW;EACX,SAAS;EACT,OAAO;EACP,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE;IACE,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;EAChC;EACA;IACE,WAAW;EACb;AACF","sourcesContent":["#footer {\n  margin: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #000;\n  width: 100%;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n\n.footer__body {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  max-width: 1400px;\n}\n\n.footer__links {\n  display: flex;\n}\n.footer__link {\n  margin: auto 2rem;\n  text-decoration: none;\n  color: white;\n}\n\n.footer__logo-img {\n  height: 2rem;\n  padding: 2rem;\n}\n\n@media only screen and (max-width: 500px) {\n  .footer__body {\n    display: inline;\n    text-align: center;\n  }\n  .footer__links {\n    flex-direction: column;\n    margin-bottom: 20px;\n    justify-content: space-between;\n  }\n  .footer__link {\n    margin: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
