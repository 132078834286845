import React, { useEffect } from "react";
import Card from "../../shared/UIElements/Card";
import AllOrders from "../AllOrders";
import "./ViewOrder.css";
import Button from "../../shared/formElements/Button";

const ViewOrder = ({ order, cardImage, backCardImage, setForm }) => {
  return (
    <div className="view_order__container">
      <div className="view_order__images-container">
        <img src={cardImage} className="cardimage" alt="" />
        <img src={backCardImage} className="cardimage" alt="" />
      </div>
      <Card>
        <div className="view_order__info-container">
          <div className="view_order__info">
            <p>{order.name}</p>
            <p>{order.shippinglineone}</p>
            <p>{order.shippinglinetwo}</p>
            <p>
              {order.city}, {order.state} {order.zipcode}
            </p>
          </div>
          <Button>Download Front Image</Button>
          <Button>Download Back Image</Button>
          <Button>Print Shipping Label</Button>
        </div>
      </Card>
    </div>
  );
};

export default ViewOrder;
