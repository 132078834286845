import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "../../shared/formElements/Button";
import { AuthContext } from "../../shared/context/auth-context";
import ErrorModal from "../../shared/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/UIElements/LoadingSpinner";
import EmptyProfileImage from "../../shared/formElements/avatar-empty.png";

import "./UpdateProfileForm.css";
import "../../user/themes/Standard.css";

const UpdateProfileForm = ({ theme }) => {
  const auth = useContext(AuthContext);

  const [userProfile, setUserProfile] = useState(null);
  const [profileImage, setProfileImage] = useState(EmptyProfileImage);
  const [backgroundImage, setBackgroundImage] = useState(EmptyProfileImage);
  // const [galleryImageOne, setGalleryImageOne] = useState(EmptyProfileImage);

  // const [youtubeLink, setYoutubeLink] = useState(null);
  const [error, setError] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [buttonText, setButtonText] = useState("Update Profile");
  // const [galleryImages, setGalleryImages] = useState([]);
  // const [maxImagesReached, setMaxImagesReached] = useState(false);
  // const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  //values for formik
  const initialValues = {
    firstname: userProfile
      ? userProfile.firstname
        ? userProfile.firstname
        : ""
      : "",
    middlename: userProfile
      ? userProfile.middlename
        ? userProfile.middlename
        : ""
      : "",
    lastname: userProfile
      ? userProfile.lastname
        ? userProfile.lastname
        : ""
      : "",
    company: userProfile
      ? userProfile.company
        ? userProfile.company
        : ""
      : "",
    title: userProfile ? (userProfile.title ? userProfile.title : "") : "",
    location: userProfile
      ? userProfile.location
        ? userProfile.location
        : ""
      : "",
    tagline: userProfile
      ? userProfile.tagline
        ? userProfile.tagline
        : ""
      : "",
    website: userProfile
      ? userProfile.website
        ? userProfile.website
        : ""
      : "",
    aboutme: userProfile
      ? userProfile.aboutme
        ? userProfile.aboutme
        : ""
      : "",
    profileimage: userProfile
      ? userProfile.profileimage
        ? userProfile.profileimage
        : ""
      : "",
    backgroundimage: userProfile
      ? userProfile.backgroundimage
        ? userProfile.backgroundimage
        : ""
      : "",
    skills: userProfile ? (userProfile.skills ? userProfile.skills : "") : "",
    youtube: userProfile
      ? userProfile.youtube
        ? userProfile.youtube
        : ""
      : "",
    facebook: userProfile
      ? userProfile?.social?.facebook
        ? userProfile.social.facebook
        : ""
      : "",
    twitter: userProfile
      ? userProfile?.social?.twitter
        ? userProfile.social.twitter
        : ""
      : "",
    linkedin: userProfile
      ? userProfile?.social?.linkedin
        ? userProfile.social.linkedin
        : ""
      : "",
    instagram: userProfile
      ? userProfile?.social?.instagram
        ? userProfile.social.instagram
        : ""
      : "",
    tiktok: userProfile
      ? userProfile?.social?.tiktok
        ? userProfile.social.tiktok
        : ""
      : "",
    pinterest: userProfile
      ? userProfile?.social?.pinterest
        ? userProfile.social.pinterest
        : ""
      : "",
    snapchat: userProfile
      ? userProfile?.social?.snapchat
        ? userProfile.social.snapchat
        : ""
      : "",
    cashapp: userProfile
      ? userProfile?.social?.cashapp
        ? userProfile.social.cashapp
        : ""
      : "",
    redirectUrl: userProfile
      ? userProfile.redirectUrl
        ? userProfile.redirectUrl
        : ""
      : ""
    // galleryimageone: userProfile
    //   ? userProfile.galleryimageone
    //     ? userProfile.galleryimageone
    //     : []
    //   : []
  };

  const updateProfile = async (values) => {
    //create new form data for patch -- cannot send as json with file
    const formData = new FormData();
    formData.append(
      "profileimage",
      values.profileimage ? values.profileimage : ""
    );
    formData.append(
      "backgroundimage",
      values.backgroundimage ? values.backgroundimage : ""
    );
    formData.append("firstname", values.firstname ? values.firstname : "");
    formData.append("middlename", values.middlename ? values.middlename : "");
    formData.append("lastname", values.lastname ? values.lastname : "");
    formData.append("company", values.company ? values.company : "");
    formData.append("title", values.title ? values.title : "");
    formData.append("location", values.location ? values.location : "");
    formData.append("tagline", values.tagline ? values.tagline : "");
    formData.append("website", values.website ? values.website : "");
    formData.append("aboutme", values.aboutme ? values.aboutme : "");
    formData.append("skills", values.skills ? values.skills : "");
    formData.append("youtube", values.youtube ? values.youtube : "");
    formData.append("facebook", values.facebook ? values.facebook : "");
    formData.append("twitter", values.twitter ? values.twitter : "");
    formData.append("linkedin", values.linkedin ? values.linkedin : "");
    formData.append("instagram", values.instagram ? values.instagram : "");
    formData.append("pinterest", values.pinterest ? values.pinterest : "");
    formData.append("snapchat", values.snapchat ? values.snapchat : "");
    formData.append("cashapp", values.cashapp ? values.cashapp : "");
    formData.append("tiktok", values.tiktok ? values.tiktok : "");
    formData.append(
      "redirectUrl",
      values.redirectUrl ? values.redirectUrl : ""
    );
    // formData.append(
    //   "galleryimageone",
    //   values.galleryimageone ? values.galleryimageone : ""
    // );
    setIsLoading(true);

    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_API}/api/profile/${userProfile.userId}`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: "Bearer " + auth.token
          }
        }
      )
      .then((response) => {
        setUserProfile(response.data.profile);
        updateSubmitButtonText();
        if (response.data.profile.youtube) {
          // var url = response.data.profile.youtube;
          // var id = url.split("?v=")[1];
          // var embedlink = "http://www.youtube.com/embed/" + id;
        }
        //setYoutubeLink(embedlink);
      })
      .catch((err) => {
        setError(err.message);
      });

    setIsEditMode(false);
    setIsLoading(false);
  };

  //handle button text
  const updateSubmitButtonText = () => {
    setButtonText("Saving...");
    setTimeout(() => {
      setButtonText("Saved");
      setSaved(true);
    }, 2000);
  };

  // const handleImageEdit = (index) => {
  //   setSelectedImageIndex(index);
  //   // Implement your logic to open a file input dialog for replacing the image
  //   // This can be achieved using a hidden file input element and triggering a click event
  // };

  // const updateGalleryImages = (newImage, imageFile) => {
  //   if (galleryImages.length < 9) {
  //     // Check if max images limit is not reached
  //     setGalleryImages([...galleryImages, newImage]);
  //   } else {
  //     setMaxImagesReached(true);
  //   }
  // };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/profile/find-profile/${auth.userId}`
      )
      .then((response) => {
        setUserProfile(response.data.profile);
        if (response.data.profile.profileimage != null || "") {
          setProfileImage(response.data.profile.profileimage);
        }
        if (response.data.profile.backgroundimage != null || "") {
          setBackgroundImage(response.data.profile.backgroundimage);
        }
        // if (response.data.profile.galleryimageone != null || "") {
        //   setGalleryImageOne(
        //     `${process.env.REACT_APP_BACKEND_API}/${response.data.profile.galleryimageone}`
        //   );
        // }
        if (response.data.profile.youtube) {
          // var url = response.data.profile.youtube;
          // var id = url.split("?v=")[1];
          // var embedlink = "http://www.youtube.com/embed/" + id;
        }
        // setYoutubeLink(embedlink);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [auth.userId]);

  const validationSchema = Yup.object({
    firstname: Yup.string().required("REQUIRED"),
    middlename: Yup.string().notRequired(),
    lastname: Yup.string().required("REQUIRED")
  });

  const onUpdateSubmit = async (values) => {
    window.scrollTo(0, 0);
    updateProfile(values);
  };

  // const backgroundImageByTheme = (theme) => {
  //   switch (theme) {
  //     case "Standard":
  //       return "update_profile__background-img";
  //     case "Artist":
  //       return "update_profile__background-img-artist";
  //     case "Realtor":
  //       return "update_profile__background-img-realtor";
  //     default:
  //       return null;
  //   }
  // };

  const onHandleError = () => {
    setError(null);
  };

  const editModeHandler = (e) => {
    e.preventDefault();
    setIsEditMode(!isEditMode);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (saved)
      setTimeout(() => {
        setButtonText("Update Profile");
        setSaved(false);
      }, 2000);
  }, [saved]);

  if (userProfile) {
    return (
      <>
        <ErrorModal error={error} onClear={onHandleError} />
        <div id="update_profile">
          <div className="signup-container">
            {isLoading && <LoadingSpinner asOverlay />}
            <h1 id="update_profile__h1">Update Profile</h1>
            <Formik
              initialValues={initialValues}
              onSubmit={onUpdateSubmit}
              validationSchema={validationSchema}
            >
              {({
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                handleChange
              }) => (
                <Form
                  className="signup-form"
                  onSubmit={handleSubmit}
                  // encType="multipart/form-data"
                >
                  <h4>Profile Image</h4>
                  <img
                    src={profileImage}
                    className="update_profile__profile-img"
                    alt="profileimage"
                    onError={() => setProfileImage(EmptyProfileImage)}
                  />
                  <input
                    name="profileimage"
                    type="file"
                    id="profileimage"
                    accept=".jpg,.png,.jpeg"
                    onChange={(e) => {
                      const fileReader = new FileReader();
                      fileReader.onload = () => {
                        if (fileReader.readyState === 2) {
                          setProfileImage(fileReader.result);
                          setFieldValue("profileimage", e.target.files[0]);
                        }
                      };
                      fileReader.readAsDataURL(e.target.files[0]);
                    }}
                  />
                  <h4>Background Image</h4>
                  <img
                    src={backgroundImage}
                    className="update_profile__background-image"
                    // className={backgroundImageByTheme(theme)}
                    alt="backgroundimage"
                    onError={() => setBackgroundImage(EmptyProfileImage)}
                    style={{
                      objectFit: "cover",
                      maxWidth: "500px",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                  />
                  <input
                    type="file"
                    id="backgroundimage"
                    name="backgroundimage"
                    accept=".jpg,.png,.jpeg"
                    onChange={(e) => {
                      const fileReader = new FileReader();
                      fileReader.onload = () => {
                        if (fileReader.readyState === 2) {
                          setBackgroundImage(fileReader.result);
                          setFieldValue("backgroundimage", e.target.files[0]);
                        }
                      };
                      fileReader.readAsDataURL(e.target.files[0]);
                    }}
                  />
                  <h3>Personal Info</h3>
                  <label>First Name</label>
                  <Field
                    id="firstname"
                    name="firstname"
                    placeholder="firstname"
                  />
                  <label htmlFor="middlename">Middle Name</label>
                  <Field
                    id="middlename"
                    name="middlename"
                    placeholder="middlename"
                  />
                  <label htmlFor="lastname">Last Name</label>
                  <Field id="lastname" name="lastname" placeholder="lastname" />
                  <label htmlFor="company">Company</label>
                  <Field id="company" name="company" placeholder="company" />
                  <label htmlFor="title">Title</label>
                  <Field id="title" name="title" placeholder="title" />
                  <label htmlFor="location">Location</label>
                  <Field
                    id="location"
                    name="location"
                    placeholder="City, State - ex. Dayton, OH"
                  />
                  <label htmlFor="tagline">Tagline</label>
                  <Field id="tagline" name="tagline" placeholder="tagline" />
                  <label htmlFor="website">Website</label>
                  <Field id="website" name="website" placeholder="website" />
                  <label htmlFor="aboutme">About Me</label>
                  <Field
                    id="aboutme"
                    name="aboutme"
                    type="textarea"
                    component="textarea"
                    rows="5"
                    placeholder="aboutme"
                  />
                  <label htmlFor="skills">Skills or Specialties</label>
                  <Field
                    id="skills"
                    name="skills"
                    type="textarea"
                    component="textarea"
                    rows="3"
                    placeholder="enter skills separated by comma."
                  />
                  <h3>Social Links</h3>
                  <label htmlFor="youtube">YouTube</label>
                  <Field
                    id="youtube"
                    name="youtube"
                    placeholder="youtube url"
                  />
                  <label htmlFor="facebook">Facebook</label>
                  <Field
                    id="facebook"
                    name="facebook"
                    placeholder="facebook link"
                  />
                  <label htmlFor="twitter">Twitter</label>
                  <Field
                    id="twitter"
                    name="twitter"
                    placeholder="twitter link"
                  />
                  <label htmlFor="linkedin">LinkedIn</label>
                  <Field
                    id="linkedin"
                    name="linkedin"
                    placeholder="linkedin link"
                  />
                  <label htmlFor="instagram">Instagram</label>
                  <Field
                    id="instagram"
                    name="instagram"
                    placeholder="instagram link"
                  />
                  <label htmlFor="tiktok">TikTok</label>
                  <Field id="tiktok" name="tiktok" placeholder="tiktok link" />
                  <label htmlFor="pinterest">Pinterest</label>
                  <Field
                    id="pinterest"
                    name="pinterest"
                    placeholder="pinterest link"
                  />
                  <label htmlFor="snapchat">Snapchat</label>
                  <Field
                    id="snapchat"
                    name="snapchat"
                    placeholder="snapchat link"
                  />
                  <label htmlFor="cashapp">Cash App</label>
                  <Field
                    id="cashapp"
                    name="cashapp"
                    placeholder="cashapp link"
                  />
                  <h3>Redirect URL</h3>
                  <label>Url</label>
                  <Field
                    id="redirectUrl"
                    name="redirectUrl"
                    placeholder="redirect url"
                  />
                  {/* Gallery Images */}
                  {/* <div className="card update_profile__card">
                    <h4>Gallery Images</h4>
                    <img
                      src={galleryImageOne}
                      className="gallery-image-icon"
                      alt="galleryimageone"
                      // onError={() => setBackgroundImage(EmptyProfileImage)}
                      style={{
                        objectFit: "cover",
                        maxWidth: "500px",
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                    />
                    <input
                      type="file"
                      id="galleryimageone"
                      name="galleryimageone"
                      accept=".jpg,.png,.jpeg"
                      onChange={(e) => {
                        const fileReader = new FileReader();
                        fileReader.onload = () => {
                          if (fileReader.readyState === 2) {
                            setGalleryImageOne(fileReader.result);
                            setFieldValue("galleryimageone", e.target.files[0]);
                          }
                        };
                        fileReader.readAsDataURL(e.target.files[0]);
                      }}
                    />
                  </div> */}
                  <Button
                    size="l"
                    type="button"
                    onClick={editModeHandler}
                    inverse
                  >
                    Cancel
                  </Button>
                  <Button size="l" type="submit">
                    {buttonText}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
};

export default UpdateProfileForm;
