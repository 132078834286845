import React from "react";
import Card from "../../shared/UIElements/Card";
import Button from "../../shared/formElements/Button";

import "./UserTile.css";
import ViewUserProfile from "./ViewUserProfile";

const UserTile = ({ user, deleteUser, selectUser, setForm }) => {
  return (
    <div className="user_tile__container">
      <Card>
        <p>{user.username}</p>
        <Button
          onClick={() => {
            setForm(<ViewUserProfile user={user} />);
            selectUser(user);
          }}
        >
          View User
        </Button>
        <Button onClick={() => deleteUser(user._id)}>Delete User</Button>
      </Card>
    </div>
  );
};

export default UserTile;
