import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Button from "../../shared/formElements/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../shared/context/auth-context";
import ErrorModal from "../../shared/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/UIElements/LoadingSpinner";
import ProfilePlaceholder from "../../shared/formElements/avatar-empty.png";

import "./UpdateVCardForm.css";

const UpdateVCard = () => {
  const auth = useContext(AuthContext);

  const [vCard, setVCard] = useState();
  const [vCardImage, setVCardImage] = useState(ProfilePlaceholder);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saved, setSaved] = useState(false);
  const [buttonText, setButtonText] = useState("Update Contact");

  //values for formik
  const initialValues = {
    firstName: vCard ? (vCard.firstName ? vCard.firstName : "") : "",
    middleName: vCard ? (vCard.middleName ? vCard.middleName : "") : "",
    lastName: vCard ? (vCard.lastName ? vCard.lastName : "") : "",
    cellphone: vCard ? (vCard.cellphone ? vCard.cellphone : "") : "",
    organization: vCard ? (vCard.organization ? vCard.organization : "") : "",
    title: vCard ? (vCard.title ? vCard.title : "") : "",
    email: vCard ? (vCard.email ? vCard.email : "") : "",
    photo: vCard ? (vCard.photo ? vCard.photo : "") : ""
  };

  // const validationSchema = Yup.object({
  //   email: Yup.string().email("Invalid email format.").required("REQUIRED"),
  //   password: Yup.string().required("REQUIRED"),
  //   confirmPassword: Yup.string()
  //     .oneOf([Yup.password, ""], "Passwords must match")
  //     .required("REQUIRED")
  // });
  const validationSchema = Yup.object({
    firstName: Yup.string().required("REQUIRED"),
    lastName: Yup.string().required("REQUIRED"),
    email: Yup.string().email("Invalid email format.").required("REQUIRED")
  });

  const updateVCard = async (values) => {
    setIsLoading(true);
    //const updateValues = JSON.stringify(values);
    const formData = new FormData();
    formData.append("firstName", values.firstName ? values.firstName : "");
    formData.append("middleName", values.middleName ? values.middleName : "");
    formData.append("lastName", values.lastName ? values.lastName : "");
    formData.append("cellphone", values.cellphone ? values.cellphone : "");
    formData.append(
      "organization",
      values.organization ? values.organization : ""
    );
    formData.append("title", values.title ? values.title : "");
    formData.append("email", values.email ? values.email : "");
    formData.append("photo", values.photo ? values.photo : "");

    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_API}/api/vCard/update/${auth.userId}`,
        formData
      )
      .then((response) => {
        setVCard(response.data.vCard);
        updateSubmitButtonText();

        if (response.data.vCard.photo != null || "") {
          setVCardImage(response.data.vCard.photo);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response.data.message);
        throw new Error(err.response.data.message);
      });
    setIsEditMode(false);
    setIsLoading(false);
  };

  const onUpdateSubmit = async (values) => {
    updateVCard(values);
    setIsEditMode(false);
  };

  const editModeHandler = (e) => {
    e.preventDefault();
    setIsEditMode(!isEditMode);
    window.scrollTo(0, 0);
  };

  const onHandleError = () => {
    setError(null);
  };

  //handle button text
  const updateSubmitButtonText = () => {
    setButtonText("Saving...");
    setTimeout(() => {
      setButtonText("Saved");
      setSaved(true);
    }, 2000);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/vcard/find-vCard/${auth.userId}`
      )
      .then((response) => {
        setVCard(response.data.vCard);
        if (response.data.vCard.photo != null || "") {
          setVCardImage(response.data.vCard.photo);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [auth.userId]);

  useEffect(() => {
    if (saved)
      setTimeout(() => {
        setButtonText("Update Contact");
        setSaved(false);
      }, 2000);
  }, [saved]);

  if (vCard) {
    return (
      <>
        <ErrorModal error={error} onClear={onHandleError} />
        <div id="update_user-page">
          <div className="update_user-container">
            {isLoading && <LoadingSpinner asOverlay />}
            <h1>Update Contact Card</h1>
            <Formik
              initialValues={initialValues}
              onSubmit={onUpdateSubmit}
              validationSchema={validationSchema}
            >
              {({
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                handleChange
              }) => (
                <Form
                  className="update_user-form"
                  encType="multipart/form-data"
                  onSubmit={handleSubmit}
                >
                  <h4>Profile Image</h4>
                  <img
                    src={vCardImage}
                    className="update_vcard__contact-image"
                    alt="profile"
                    onError={() => setVCardImage(ProfilePlaceholder)}
                  />
                  <input
                    type="file"
                    id="photo"
                    accept=".jpg,.png,.jpeg"
                    onChange={(e) => {
                      const fileReader = new FileReader();
                      fileReader.onload = () => {
                        if (fileReader.readyState === 2) {
                          setVCardImage(fileReader.result);
                          setFieldValue("photo", e.target.files[0]);
                        }
                      };
                      fileReader.readAsDataURL(e.target.files[0]);
                    }}
                  />
                  <label>First Name</label>
                  <Field
                    id="firstName"
                    name="firstName"
                    type="firstName"
                    placeholder="first name"
                  />
                  <label>Middle Name</label>
                  <Field
                    id="middleName"
                    name="middleName"
                    type="middleName"
                    placeholder="middle middlename"
                  />
                  <label>Last Name</label>
                  <Field
                    id="lastName"
                    name="lastName"
                    type="lastName"
                    placeholder="last name"
                  />
                  <label>Cellphone</label>
                  <Field
                    id="cellphone"
                    name="cellphone"
                    type="string"
                    placeholder="cellphone"
                  />
                  <label>Email</label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    placeholder="email"
                  />
                  <label>Organization</label>
                  <Field
                    id="organization"
                    name="organization"
                    type="organization"
                    placeholder="organization"
                  />
                  <label>Title</label>
                  <Field
                    id="title"
                    name="title"
                    type="title"
                    placeholder="title"
                  />
                  <Button
                    size="l"
                    type="button"
                    onClick={editModeHandler}
                    inverse
                  >
                    Cancel
                  </Button>
                  <Button size="l" type="submit">
                    {buttonText}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="">
        <LoadingSpinner />
      </div>
    );
  }
};

export default UpdateVCard;
