import React, { useState, useEffect } from "react";

import "./ViewUserProfile.css";
import axios from "axios";

const ViewUserProfile = ({ user }) => {
  const [profile, setProfile] = useState({});

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/profile/find-profile/${user._id}`
      )
      .then((response) => {
        setProfile(response.data.profile);
      })
      .catch((err) => console.log(err));
  }, [user]);
  return (
    <div>
      <img src={profile.profileimage} alt="" />
      <p>Username: {user.username}</p>
      <p>email: {user.email}</p>
    </div>
  );
};

export default ViewUserProfile;
