import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import "./UserProfile.css";
import { useParams } from "react-router-dom";
import Standard from "../themes/Standard";
import Artist from "../themes/Artist";
import Realtor from "../themes/Realtor";
import { AuthContext } from "../../shared/context/auth-context";
import download from "downloadjs";
import LoadingSpinner from "../../shared/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/UIElements/ErrorModal";

const UserProfile = () => {
  const auth = useContext(AuthContext);

  //get param
  const { username } = useParams();

  //data in place of db api call
  const socials = [];
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);

  const setSocialArray = (profileData) => {
    if (profileData.social.facebook !== "") {
      socials.push({
        channel: "facebook",
        link: profileData?.social?.facebook
      });
    }
    if (profileData.social.twitter !== "") {
      socials.push({ channel: "twitter", link: profileData?.social?.twitter });
    }
  };

  //make call to get profile from url
  const GetUserId = async () => {
    let lowerUsername = username.toLowerCase();
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/user/getuser/${lowerUsername}`
      )
      .then((response) => {
        setUser(response.data.user);
        setUserId(response.data.user._id);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const GetProfile = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/profile/find-profile/${userId}`
      )
      .then((response) => {
        setProfileData(response.data.profile);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  // const profileSelection = (theme) => {
  //   switch (theme) {
  //     case "Standard":
  //       return (
  //         <Standard profileData={profileData} handleDownload={handleDownload} />
  //       );
  //     case "Artist":
  //       return <Artist profileData={profileData} handleDownload={handleDownload} />;
  //     case "Realtor":
  //       return <Realtor profileData={profileData} handleDownload={handleDownload} />;
  //     default:
  //       return null;
  //   }
  // };

  const handleDownload = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/api/vcard/download/${userId}`
    );

    const blob = await res.blob();

    download(blob, "contact.vcf");
  };

  const onHandleError = () => {
    setError(null);
  };

  //get user id on mount
  useEffect(() => {
    GetUserId();
  }, []);

  //get profile from user id
  useEffect(() => {
    if (user !== null || undefined) GetProfile();
  }, [userId]);

  //check profile type and return theme
  if (profileData) {
    return (
      <div className="">
        <ErrorModal error={error} onClear={onHandleError} />
        <Standard profileData={profileData} handleDownload={handleDownload} />

        {/* {profileSelection(user.theme)} */}
      </div>
    );
  }

  return (
    <div
      className=""
      style={{ display: "flex", justifyContent: "center", marginTop: "10%" }}
    >
      <LoadingSpinner />
    </div>
  );
};

export default UserProfile;
