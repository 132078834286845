import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div id="privacy_policy">
      <div className="privacy_policy__body">
        <h1>Privacy Policy</h1>
        <p>Effective Date: [Insert Effective Date]</p>
        <p>
          This Privacy Policy ("Policy") outlines how The Adapt Card ("Company,"
          "we," "us," or "our") collects, uses, and protects your personal
          information when you use our services. By accessing or using our
          services, you consent to the practices described in this Policy.
        </p>
        <h3>1. Information We Collect</h3>
        <p>
          1.1. Personal Information: We may collect personal information,
          including but not limited to names, contact information, and other
          identifiers, when you use our services.
        </p>
        <p>
          1.2. User-Generated Content: Information you provide when using our
          services, such as text, images, and other content, may be collected.
        </p>
        <h3>2. How We Use Your Information</h3>
        <p>
          2.1. To Provide Services: We may use your information to provide,
          maintain, and improve our services, including the customization and
          personalization of your experience.
        </p>
        <p>
          2.2. Communication: We may use your information to communicate with
          you about our services, promotions, and updates.
        </p>
        <p>
          2.3. Security: We use your information to ensure the security and
          integrity of our services and to protect against unauthorized access
          or use.
        </p>
        <h3>3. Information Sharing</h3>
        <p>
          3.1. Third-Party Service Providers: We may share your information with
          third-party service providers who assist us in operating and
          maintaining our services.
        </p>
        <p>
          3.2. Legal Requirements: We may disclose your information in response
          to legal requirements, such as court orders, or to protect our rights,
          property, or safety.
        </p>
        <h3>4. Your Choices</h3>
        <p>
          4.1. Access and Update: You may access and update your personal
          information through your account settings.
        </p>
        <p>
          4.2. Opt-Out: You may opt-out of receiving promotional communications
          from us by following the unsubscribe instructions in the
          communication.
        </p>
        <h3>5. Data Security</h3>
        <p></p>
        5.1. We employ reasonable and industry-standard security measures to
        protect your information. However, no method of transmission over the
        internet is entirely secure, and we cannot guarantee the absolute
        security of your data.
        <h3>6. Children's Privacy</h3>
        <p>
          6.1. Our services are not directed at individuals under the age of 18.
          We do not knowingly collect or maintain personal information from
          children under this age.
        </p>
        <h3>7. Changes to this Policy</h3>
        <p>
          7.1. We reserve the right to modify this Policy at any time. We will
          notify you of any material changes by posting the updated Policy on
          our website or through other appropriate means.
        </p>
        <h3>8. Contact Information</h3>
        <p>
          8.1. If you have questions or concerns about this Privacy Policy,
          please contact us at info@theadaptcard.com. By using our services, you
          acknowledge that you have read, understood, and agreed to this Privacy
          Policy.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
