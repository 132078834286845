// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view_order__card-image {
  padding: 30px;
}
.view_order__card-image {
  width: 3.375in;
  height: 2.125in;
  object-fit: cover;
  overflow: hidden;
}
.view_order__images-container {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/super-admin/components/ViewOrder.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,aAAa;AACf","sourcesContent":[".view_order__card-image {\n  padding: 30px;\n}\n.view_order__card-image {\n  width: 3.375in;\n  height: 2.125in;\n  object-fit: cover;\n  overflow: hidden;\n}\n.view_order__images-container {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
