// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  position: relative;
  padding: 2rem 3rem;
  box-shadow: 2px 2px 5px #ccc;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: auto auto;
  align-content: center;
}

.card h1 {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/shared/UIElements/Card.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,4BAA4B;EAC5B,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".card {\n  position: relative;\n  padding: 2rem 3rem;\n  box-shadow: 2px 2px 5px #ccc;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  margin: auto auto;\n  align-content: center;\n}\n\n.card h1 {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
