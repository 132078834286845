import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../shared/formElements/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./Login.css";
import Card from "../shared/UIElements/Card";
import { AuthContext } from "../shared/context/auth-context";
import ErrorModal from "../shared/UIElements/ErrorModal";
import LoadingSpinner from "../shared/UIElements/LoadingSpinner";

const Login = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //values for formik
  const initialValues = {
    email: "",
    password: ""
  };

  //validate fields
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format.").required("REQUIRED"),
    password: Yup.string().required("REQUIRED")
  });

  //handle pressed login
  const onLoginSubmit = async (values) => {
    const postValues = JSON.stringify(values);

    setIsLoading(true);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_API}/api/user/login`, postValues, {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response.data.message);
        throw new Error(err.response.data.message);
      });
  };

  //handle errors in form
  const onHandleError = () => {
    setError(null);
  };

  useEffect(() => {
    if (user) {
      setIsLoading(false);
      if (!error) {
        auth.login(user.userId, user.token, user.userType);
        window.scrollTo(0, 0);
        navigate(`/admin/${user.userId}`);
      }
    }
  }, [user]);

  return (
    <>
      <ErrorModal error={error} onClear={onHandleError} />
      <div id="login-page">
        <Card className="login-container">
          {isLoading && <LoadingSpinner asOverlay />}
          <h1>Login</h1>
          <Formik
            initialValues={initialValues}
            onSubmit={onLoginSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, touched }) => (
              <Form className="login-form">
                <Field id="email" name="email" placeholder="email" />
                {touched.email && errors.email && (
                  <div className="login__error">{errors.email}</div>
                )}
                <Field
                  id="password"
                  name="password"
                  type="password"
                  placeholder="password"
                />
                <Button size="l" type="submit">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
          <button
            type="button"
            className="login_form__reset_password_button"
            onClick={() => {
              navigate("/request-new-password");
            }}
          >
            Forgot Password?
          </button>
        </Card>
      </div>
    </>
  );
};

export default Login;
