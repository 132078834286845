import { PaymentElement, AddressElement } from "@stripe/react-stripe-js";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "../formElements/Button";
import { paymentContext } from "./SignupForm";

import "./PaymentForm.css";

const PaymentForm = ({
  formIndex,
  setFormIndex,
  amount,
  formType,
  updateErrorMessage,
  setReplacePaymentDetails
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const payment = useContext(paymentContext);
  //const {hasPaid, setHasPaid} = useContext(paymentContext);

  //Check if the payment object or the hasPaid property is undefined
  const hasPaid =
    payment && payment.hasPaid !== undefined ? payment.hasPaid : false;
  const setHasPaid = payment ? payment.setHasPaid : null;

  const paymentDetails =
    payment && payment.paymentDetails !== undefined
      ? payment.paymentDetails
      : false;
  const setPaymentDetails = payment ? payment.setPaymentDetails : null;

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/completion`
        },
        redirect: "if_required"
      });

      if (result.error) {
        setMessage(result.error.message);
        updateErrorMessage(result.error.message);
        setIsProcessing(false);
      } else if (
        result.paymentIntent &&
        result.paymentIntent.status === "succeeded"
      ) {
        if (formType === "card-replacement") {
          setReplacePaymentDetails(result.paymentIntent);
        } else {
          setPaymentDetails(result.paymentIntent);
          setHasPaid(true);
        }
      } else {
        setMessage("An unexpected error occurred.");
        setIsProcessing(false);
      }
    } catch (err) {
      setMessage("An unexpected error occurred.");
      setIsProcessing(false);
    }
  };

  return (
    <>
      <form
        id="payment-form"
        className={formType === "card-replacement" ? "payment-form" : ""}
        onSubmit={handleSubmit}
      >
        <h2>Shipping Information</h2>
        <AddressElement
          options={{
            mode: "shipping",
            allowedCountries: ["US"],
            blockPoBox: true,
            billingAddressCollection: "full"
          }}
        />
        <h2>Payment Information</h2>
        <PaymentElement id="payment-element" />
        <Button
          size="l"
          disabled={isProcessing || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">
            {isProcessing
              ? "Processing ... "
              : `Pay now: $${amount.amount / 100}`}
          </span>
        </Button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
      {formType !== "card-replacement" ? (
        <Button onClick={() => setFormIndex(formIndex - 1)}>Back</Button>
      ) : null}
    </>
  );
};

export default PaymentForm;
