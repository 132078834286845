import React from "react";
import BackgroundImage from "../../shared/img/realtorBackground.png";
import ProfileImage from "../../shared/img/ArtistProfile.png";
import BrokerageLogo from "../../shared/img/AdaptLogo.png";
import "./Realtor.css";
import FacebookIcon from "../../shared/img/facebookIcon.png";
import TwitterIcon from "../../shared/img/twitterIcon.png";
import InstagramIcon from "../../shared/img/instagramIcon.png";
import LinkedinIcon from "../../shared/img/linkedinIcon.png";
import PinterestIcon from "../../shared/img/pinterestIcon.png";
import YoutubeIcon from "../../shared/img/youtubeIcon.png";
import SnapchatIcon from "../../shared/img/snapchatIcon.png";
import WebsiteIcon from "../../shared/img/websiteIcon.png";
import BedIcon from "../../shared/img/bedIcon.png";
import BathroomIcon from "../../shared/img/bathroomIcon.png";
import ThemeFooter from "./components/ThemeFooter";

const Realtor = () => {
  const backgroundStyle = {
    backgroundImage: `url(${BackgroundImage})` // Use the correct syntax here
  };
  return (
    <>
      <div id="realtor_theme__container">
        <div
          className="realtor_theme__background-image-container"
          style={backgroundStyle}
        ></div>
        <div className="realtor_theme__data-container">
          <div className="realtor_theme__profile-image-container">
            <img
              src={ProfileImage}
              className="realtor_theme__profile-image"
              alt=""
            />
          </div>
          <div className="realtor_theme__data">
            <h2>Seth Stephens</h2>
            <div className="realtor_theme__data-info">
              <h3>I'd rather be disc golfing.</h3>
              <div className="realtor_theme__data-location-logo-container">
                <div className="realtor_theme__data-location">
                  <p>Realtor</p>
                  <p>Dayton, OH</p>
                </div>
                <img
                  src={BrokerageLogo}
                  className="realtor_theme__data-logo"
                  alt=""
                />
              </div>
            </div>
            <div className="realtor_theme__data-container-services">
              <p className="realtor_theme__data-container-service">
                Commercial
              </p>
              <p className="realtor_theme__data-container-service">
                Residential
              </p>
              <p className="realtor_theme__data-container-service">Rentals</p>
            </div>
            <div className="realtor_theme__button-container">
              <button className="realtor_theme__large-button">
                Visit my Website
              </button>
              <div className="realtor_theme__contact-buttons">
                <button className="realtor_theme__contact-button">
                  Save my contact
                </button>
                <button className="realtor_theme__share-contact-button">
                  Share your contact
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="artist_theme__social-links-container">
          <a
            href={"http://localhost:3000"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={WebsiteIcon}
              className="artist_theme__social-icon"
              alt=""
            />
          </a>
          <img
            src={FacebookIcon}
            className="artist_theme__social-icon"
            alt=""
          />
          <img src={TwitterIcon} className="artist_theme__social-icon" alt="" />
          <img
            src={InstagramIcon}
            className="artist_theme__social-icon"
            alt=""
          />
          <img
            src={LinkedinIcon}
            className="artist_theme__social-icon"
            alt=""
          />
          <img
            src={PinterestIcon}
            className="artist_theme__social-icon"
            alt=""
          />
          <img src={YoutubeIcon} className="artist_theme__social-icon" alt="" />
          <img
            src={SnapchatIcon}
            className="artist_theme__social-icon"
            alt=""
          />
        </div>
        <div className="realtor_theme__listing-container">
          <h2>Featured Listings</h2>
          <div className="realtor_theme__listings">
            <div className="realtor_theme__listing">
              <img
                src={BackgroundImage}
                className="realtor_theme__listing-image"
                alt=""
              />
              <div className="realtor_theme__listing-info">
                <h4>1322 Burlington Placehhhhhhhhhhhhhheee</h4>
                <div className="realtor_theme__listing-stats-container">

                <div className="realtor_theme__listing-stats">
                  <p>3</p>
                  <img src={BedIcon} alt="" />
                  <p>2.5</p>
                  <img src={BathroomIcon} alt="" />
                </div>
                <div className="realtor_theme__list-price">
                  <p>$370k</p>
                </div>
                </div>
              </div>
            </div>
            <div className="realtor_theme__listing">
              <img
                src={BackgroundImage}
                className="realtor_theme__listing-image"
                alt=""
              />
              <div className="realtor_theme__listing-info">
                <h4>1322 Burlington Placehhhhhhhhhhhhhheee</h4>
                <div className="realtor_theme__listing-stats-container">

                <div className="realtor_theme__listing-stats">
                  <p>3</p>
                  <img src={BedIcon} alt="" />
                  <p>2.5</p>
                  <img src={BathroomIcon} alt="" />
                </div>
                <div className="realtor_theme__list-price">
                  <p>$1.2M</p>
                </div>
                </div>
              </div>
            </div>
            <div className="realtor_theme__listing">
              <img
                src={BackgroundImage}
                className="realtor_theme__listing-image"
                alt=""
              />
              <div className="realtor_theme__listing-info">
                <h4>8777 Nimble Dr</h4>
                <div className="realtor_theme__listing-stats-container">

                <div className="realtor_theme__listing-stats">
                  <p>2</p>
                  <img src={BedIcon} alt="" />
                  <p>1.5</p>
                  <img src={BathroomIcon} alt="" />
                </div>
                <div className="realtor_theme__list-price">
                  <p>$209,999</p>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ThemeFooter />
      </div>
    </>
  );
};

export default Realtor;
