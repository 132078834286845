import React, { useState } from "react";
import Button from "../shared/formElements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faAddressBook,
  faShare,
  faWater,
  faDiamondTurnRight,
  faHashtag,
  faGem
} from "@fortawesome/free-solid-svg-icons";
import HeroPhoneImage from "../shared/img/RealtorProfileExample.png";
import PhoneImage from "../shared/img/phone-img.png";

import "./Home.css";

const faq = [
  {
    id: 1,
    title: "Does it work on any phone?",
    content:
      "Most modern smartphones have NFC technology. If you’ve used Apple Pay or Android Pay, you’re already familiar with using NFC.<br /><br />We suggest a QR Code printed on the back of your Adapt Card so the recipient can simply point their camera at it to access the same page."
  },
  {
    id: 2,
    title: "Does it work on any phone?",
    content:
      "Most modern smartphones have NFC technology. If you’ve used Apple Pay or Android Pay, you’re already familiar with using NFC.<br /><br />We suggest a QR Code printed on the back of your Adapt Card so the recipient can simply point their camera at it to access the same page."
  }
];

const AccordianItem = ({ title, content }) => {
  const [isActive, setActive] = useState(false);

  return (
    <div className="accordian-item">
      <div className="accordian-title" onClick={() => setActive(!isActive)}>
        <div className="">{title}</div>
        <div className="">{isActive ? "-" : "+"}</div>
      </div>
      {isActive && <div className="accordian-content">{content}</div>}
    </div>
  );
};

const Home = () => {
  return (
    <div id="home">
      <div className="home__hero-container">
        <div className="home__hero-content">
          <h1>Smart Business Cards</h1>
          <h2>Smarter Networking</h2>
          <p>
            Instantly increase engagement from every first time interaction.
          </p>
          <Button to="/signup" size="big" inverse>
            Get My Card
          </Button>
        </div>
        <div className="home__hero-image">
          <img src={PhoneImage} className="home__hero-image-small-img" alt="" />
          <img src={HeroPhoneImage} className="home__hero-image-img" alt="" />
        </div>
      </div>
      <div className="home__feature-one-bg">
        <div className="home__feature-one-container">
          <div className="home__feature-one-content">
            <h2>Turn casual conversations into leads.</h2>
            <p>
              A no-brainer way to make your team stand out from the competition
              and make more sales from what would be a thrown out paper business
              card.
              <br />
              <br />
              With unlimited taps, your team can turn any first time interaction
              into a true lead by putting actionable steps easily at the
              fingertips of each potential customer.
            </p>
            <Button to="/signup" size="big" inverse>
              Get My Card
            </Button>
          </div>
          <div className="home__feature-one-img">
            <img src={PhoneImage} alt="" />
          </div>
        </div>
      </div>
      <div id="cta" className="home__call-to-action">
        <div className="home__call-to-action-content">
          <h2 className="home__call-to-action-content-h2">
            Make it easy to contact you, follow your channels, and take the next
            step in your process.
          </h2>
          <Button inverse>Get Your Card</Button>
        </div>
      </div>
      <div className="home__roi-container">
        <div className="home__roi">
          <div className="home__roi-content">
            <h2>ROI</h2>
            <p>
              Did you know an average up to{" "}
              <strong>
                88% of business cards get lost or thrown away after receiving
                it?
              </strong>{" "}
              Skyrocket your engagement and conversions by putting the most
              valuable information at the fingertips of anyone you encounter.
            </p>
          </div>
          <div className="home__roi-image">
            <img src={PhoneImage} alt="" />
          </div>
        </div>
      </div>
      <div className="home__features">
        <div className="home__features-content">
          <h2>Features</h2>
        </div>
        <div className="home__features-icons">
          <div className="home__features-icon">
            <FontAwesomeIcon
              icon={faAddressBook}
              size="5x"
              className="home__feature_icon"
            />
            <h3>Share Contact</h3>
            <p>A simple tap downloads your info to their contacts.</p>
          </div>
          <div className="home__features-icon">
            <FontAwesomeIcon
              icon={faShare}
              size="5x"
              className="home__feature_icon"
            />
            <h3>Recieve Contact</h3>
            <p>
              A short form with auto fill allows them to share their contact to
              you in a few taps.
            </p>
          </div>
          <div className="home__features-icon">
            <FontAwesomeIcon
              icon={faWater}
              size="5x"
              className="home__feature_icon"
            />
            <h3>Your Flow</h3>
            <p>
              Custom designs are based on what you percieve as a "perfect first
              time interaction".
            </p>
          </div>
          <div className="home__features-icon">
            <FontAwesomeIcon
              icon={faDiamondTurnRight}
              size="5x"
              className="home__feature_icon"
            />
            <h3>Redirect URL</h3>
            <p>
              Changing the destination of your card is as simple as updating
              what url you want them to visit.
            </p>
          </div>
          <div className="home__features-icon">
            <FontAwesomeIcon
              icon={faHashtag}
              size="5x"
              className="home__feature_icon"
            />
            <h3>Social Links</h3>
            <p>Make it easy for people to follow your channels.</p>
          </div>
          <div className="home__features-icon">
            <FontAwesomeIcon
              icon={faGem}
              size="5x"
              className="home__feature_icon"
            />
            <h3>Custom Cards</h3>
            <p>Sometimes it's the bling that counts.</p>
          </div>
        </div>
      </div>
      <div className="home__faq">
        <div className="home__faq-content">
          <h2>FAQ</h2>
        </div>
        <div className="home__faq-accordian">
          {faq.map(({ id, title, content }) => (
            <AccordianItem key={id} title={title} content={content} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
