import React from "react";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div id="terms_and_conditions">
      <div className="terms_and_conditions__body">
        <h1>Terms and Conditions</h1>
        <p>
          Effective Date: [Insert Effective Date] Please read these Terms and
          Conditions ("Terms") carefully before using the services provided by
          The Adapt Card ("Company," "we," "us," or "our"). By accessing or
          using our services, you agree to comply with and be bound by these
          Terms. If you do not agree to these Terms, please do not use our
          services.
        </p>
        <h3>1. Description of Services</h3>
        <p>
          1.1. The Adapt Card offers smart NFC cards that are specifically
          programmed for individual users. These cards are designed to
          facilitate easier networking interactions.
        </p>
        <h3>2. User Responsibilities</h3>
        <p>
          2.1. Users of our smart NFC cards are responsible for their card's
          use, security, and any interactions that occur as a result of using
          the card.
        </p>
        <h3>3. Returns and Refunds</h3>
        <p>
          3.1. All sales of our smart NFC cards are final. Due to the
          personalized nature of our cards, we do not offer returns, refunds, or
          exchanges.
        </p>
        <h3>4. Networking Results</h3>
        <p>
          4.1. The Adapt Card is not responsible for the success or outcomes of
          networking interactions facilitated by our smart NFC cards. Networking
          effectiveness depends on various factors, including individual efforts
          and circumstances.
        </p>
        <h3>5. Privacy and Data Security</h3>
        <p>
          5.1. We take user privacy and data security seriously. Please refer to
          our Privacy Policy for information on how we collect, use, and protect
          your personal information.
        </p>
        <h3>6. User Conduct</h3>
        <p>
          6.1. Users are expected to use our services responsibly and in
          accordance with applicable laws and regulations.
        </p>
        <h3>7. Intellectual Property</h3>
        <p>
          7.1. Our smart NFC cards and associated materials are protected by
          intellectual property laws. Users may not reproduce, distribute, or
          modify our products without prior written consent.
        </p>
        <h3>8. Limitation of Liability</h3>
        <p>
          8.1. The Adapt Card shall not be liable for any indirect, incidental,
          special, consequential, or punitive damages arising out of or in
          connection with the use of our services.
        </p>
        <h3>9. Modifications to Terms</h3>
        <p>
          9.1. We reserve the right to modify these Terms at any time. Updated
          Terms will be posted on our website, and it is your responsibility to
          review them periodically.
        </p>
        <h3>10. Termination</h3>
        <p>
          10.1. We reserve the right to terminate access to our services at our
          discretion if users violate these Terms or engage in any behavior that
          may harm our company or other users.
        </p>
        <h3>11. Governing Law</h3>
        <p>
          11.1. These Terms shall be governed by and construed in accordance
          with the laws of OHIO, without regard to its conflict of law
          principles.
        </p>
        <h3>12. Contact Information</h3>
        <p>
          12.1. If you have any questions or concerns about these Terms, please
          contact us at info@theadaptcard.com. By using our services, you
          acknowledge that you have read, understood, and agreed to these Terms
          and our Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
