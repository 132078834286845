import React from "react";
import Modal from "./Modal";
import Button from "../formElements/Button";

const WarningModal = (props) => {
  return (
    <Modal
      onCancel={props.onClear}
      header="You are about to delete your account!"
      show={props.warning}
      footer={
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button onClick={props.clear} inverse>
            Do <b>NOT</b> delete my account.
          </Button>
          <Button onClick={props.deleteAccount}>
            <b>Yes,</b> Delete my account.
          </Button>
        </div>
      }
    >
      <p>{props.children}</p>
    </Modal>
  );
};

export default WarningModal;
