// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.super_admin__profile {
  display: grid;
}

.super_admin__users_container {
  display: flex;
  padding: 50px 20px;
  flex-wrap: wrap;
}

.super_admin__users_search_input {
}
.super_admin__search_bar_container {
  display: flex;
  justify-content: center;
  width: 100%;
}
#super_admin__search_bar {
  margin: 5px auto;
  width: 100%;
}
.super_admin__search_bar_container_input {
  width: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/user/pages/SuperAdmin.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,eAAe;AACjB;;AAEA;AACA;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,YAAY;AACd","sourcesContent":[".super_admin__profile {\n  display: grid;\n}\n\n.super_admin__users_container {\n  display: flex;\n  padding: 50px 20px;\n  flex-wrap: wrap;\n}\n\n.super_admin__users_search_input {\n}\n.super_admin__search_bar_container {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n#super_admin__search_bar {\n  margin: 5px auto;\n  width: 100%;\n}\n.super_admin__search_bar_container_input {\n  width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
