// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#login-page {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  display: flex;
  flex-direction: column;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  max-width: 80vw;
}
.login-form input {
  padding: 10px;
  margin-top: 10px;
  font-size: 18px;
}

.login-header h1 {
  text-align: center;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.login_form__reset_password_button {
  background-color: none;
  background: none;
  color: #5fbcca;
  border: none;
}

.login__error {
  padding: 2px;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/home/Login.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,SAAS;AACX","sourcesContent":["#login-page {\n  width: 100%;\n  height: 90vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.login-container {\n  display: flex;\n  flex-direction: column;\n}\n\n.login-form {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  width: 400px;\n  max-width: 80vw;\n}\n.login-form input {\n  padding: 10px;\n  margin-top: 10px;\n  font-size: 18px;\n}\n\n.login-header h1 {\n  text-align: center;\n}\n\n.btn-container {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 1rem;\n}\n\n.login_form__reset_password_button {\n  background-color: none;\n  background: none;\n  color: #5fbcca;\n  border: none;\n}\n\n.login__error {\n  padding: 2px;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
