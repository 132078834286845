import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import MainNavigation from "./shared/navigation/MainNavigation";
import NoNavigation from "./shared/navigation/NoNavigation";
import { AuthContext } from "./shared/context/auth-context";
import Home from "./home/Home";
import UserProfile from "./user/pages/UserProfile";
import Signup from "./home/Signup";
import Login from "./home/Login";
import Pricing from "./home/Pricing";
import About from "./home/About";
import ContactUs from "./home/ContactUs";
import ScheduleCustomCreation from "./home/ScheduleCustomCreation";
import SuperAdmin from "./user/pages/SuperAdmin";
import UserAdmin from "./user/pages/UserAdmin";
import SideDrawer from "./shared/navigation/SideDrawer";
import { useAuth } from "./shared/hooks/auth-hook";
import "./App.css";
import CardReplacement from "./shared/forms/CardReplacement";
import RequestNewPassword from "./shared/forms/RequestNewPassword";
import ResetPassword from "./shared/forms/ResetPassword";
import TermsAndConditions from "./home/TermsAndConditions";
import PrivacyPolicy from "./home/PrivacyPolicy";

function App() {
  const { token, login, logout, userId, userType } = useAuth();
  let routes;
  if (token) {
    routes = (
      <Routes>
        <Route element={<MainNavigation />}>
          <Route path="/" element={<Home />} exact />
          <Route path="/pricing" element={<Pricing />} exact />
          <Route path="/about" element={<About />} exact />
          <Route path="/contact" element={<ContactUs />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/schedule-custom-creation"
            element={<ScheduleCustomCreation />}
          />
          <Route
            path={`/admin/:${userId}`}
            element={
              userType === "super-admin" ? <SuperAdmin /> : <UserAdmin />
            }
          />
          <Route
            path={`/card-replacement/:${userId}`}
            element={<CardReplacement />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
        <Route element={<NoNavigation />}>
          {/* FIX USER PROFILE TO DISPLAY CORRECT PROFILE */}
          <Route path="/profile/:username" element={<UserProfile />} />
        </Route>
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route element={<MainNavigation />}>
          <Route path="/" element={<Home />} exact />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about" element={<About />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/schedule-custom-creation"
            element={<ScheduleCustomCreation />}
          />
          <Route
            path="/request-new-password"
            element={<RequestNewPassword />}
          />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
        <Route element={<NoNavigation />}>
          <Route path="/profile/:username" element={<UserProfile />} />
        </Route>
      </Routes>
    );
  }
  return (
    <>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          userId: userId,
          token: token,
          userType: userType,
          login: login,
          logout: logout
        }}
      >
        <SideDrawer></SideDrawer>
        <Router>
          <main>{routes}</main>
        </Router>
      </AuthContext.Provider>
    </>
  );
}

export default App;
