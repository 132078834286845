// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  padding: 1rem 0.5rem;
  background: #5fbcca;
  color: white;
  border-radius: 8px 8px 0 0;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
`, "",{"version":3,"sources":["webpack://./src/shared/UIElements/Modal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,SAAS;EACT,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,uBAAuB;IACvB,YAAY;EACd;AACF;;AAEA;EACE,6BAA6B;EAC7B,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,UAAU;AACZ;;AAEA;EACE,6BAA6B;EAC7B,UAAU;EACV,qBAAqB;AACvB","sourcesContent":[".modal {\n  z-index: 100;\n  position: fixed;\n  top: 22vh;\n  left: 10%;\n  width: 80%;\n  background: white;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);\n  border-radius: 8px;\n}\n\n.modal__header {\n  padding: 1rem 0.5rem;\n  background: #5fbcca;\n  color: white;\n  border-radius: 8px 8px 0 0;\n}\n\n.modal__header h2 {\n  margin: 0.5rem;\n}\n\n.modal__content {\n  padding: 1rem 0.5rem;\n}\n\n.modal__footer {\n  padding: 1rem 0.5rem;\n}\n\n@media (min-width: 768px) {\n  .modal {\n    left: calc(50% - 20rem);\n    width: 40rem;\n  }\n}\n\n.modal-enter {\n  transform: translateY(-10rem);\n  opacity: 0;\n}\n\n.modal-enter-active {\n  transform: translateY(0);\n  opacity: 1;\n  transition: all 200ms;\n}\n\n.modal-exit {\n  transform: translateY(0);\n  opacity: 1;\n}\n\n.modal-exit-active {\n  transform: translateY(-10rem);\n  opacity: 0;\n  transition: all 200ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
