import React from "react";
import MainContactForm from "../shared/forms/MainContactForm";

import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className="form-container">
      <MainContactForm />
    </div>
  );
};

export default ContactUs;
