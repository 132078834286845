// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standard_social_link__container {
  height: 80px;
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;
  box-shadow: 0px 5px 5px -5px #000;
  margin-bottom: 10px;
  text-decoration: none;
  color: inherit;
}
.standard_social_link__icon {
  display: flex;
  justify-content: center;
}

.standard_social_link__data h4 {
  margin: 8px 0;
}
`, "",{"version":3,"sources":["webpack://./src/user/themes/components/StandardSocialLink.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iCAAiC;EACjC,mBAAmB;EACnB,qBAAqB;EACrB,cAAc;AAChB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".standard_social_link__container {\n  height: 80px;\n  display: grid;\n  grid-template-columns: 20% 80%;\n  align-items: center;\n  box-shadow: 0px 5px 5px -5px #000;\n  margin-bottom: 10px;\n  text-decoration: none;\n  color: inherit;\n}\n.standard_social_link__icon {\n  display: flex;\n  justify-content: center;\n}\n\n.standard_social_link__data h4 {\n  margin: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
