// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about__introduction {
  background-color: #000;
  color: #fff;
  min-height: 90vh;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  margin: auto auto;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.about__welcome {
  padding: 1rem 5rem;
}
.about__network {
  padding: 1rem 5rem;
}
.about__trust {
  padding: 1rem 5rem;
}
.about__standout {
  padding: 1rem 5rem;
}
.about__convenience {
  padding: 1rem 5rem;
}
.about__business_process {
  padding: 1rem 5rem;
}
.about__customized {
  padding: 1rem 5rem;
}
.about__join_us {
  padding: 1rem 5rem;
}
.about__experience {
  padding: 1rem 5rem;
}
.about__call_to_action {
  padding: 1rem 5rem;
}
`, "",{"version":3,"sources":["webpack://./src/home/About.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".about__introduction {\n  background-color: #000;\n  color: #fff;\n  min-height: 90vh;\n  max-width: 1400px;\n  display: flex;\n  flex-wrap: wrap;\n  margin: auto auto;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}\n.about__welcome {\n  padding: 1rem 5rem;\n}\n.about__network {\n  padding: 1rem 5rem;\n}\n.about__trust {\n  padding: 1rem 5rem;\n}\n.about__standout {\n  padding: 1rem 5rem;\n}\n.about__convenience {\n  padding: 1rem 5rem;\n}\n.about__business_process {\n  padding: 1rem 5rem;\n}\n.about__customized {\n  padding: 1rem 5rem;\n}\n.about__join_us {\n  padding: 1rem 5rem;\n}\n.about__experience {\n  padding: 1rem 5rem;\n}\n.about__call_to_action {\n  padding: 1rem 5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
