// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_tile__cardimage {
  height: 60px;
  width: 100px;
  object-fit: cover;
  overflow: hidden;
  margin: 0 3px;
  border: 1px solid #8d8c8c;
  box-shadow: 3px 3px 3px #ccc;
  border-radius: 2px;
}

.order_tile__container {
  display: flex;
  justify-content: space-evenly;
}

.order_tile__card-container {
  justify-content: center;
}

.order_tile__container p {
  margin: auto auto;
}

.order_tile__button_container {
  display: flex;
  justify-content: space-evenly;
}

.order_tile__button {
  padding: 10px 20px;
  display: flex;
}

.order_tile__icon {
  padding-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/super-admin/components/OrderTile.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,yBAAyB;EACzB,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".order_tile__cardimage {\n  height: 60px;\n  width: 100px;\n  object-fit: cover;\n  overflow: hidden;\n  margin: 0 3px;\n  border: 1px solid #8d8c8c;\n  box-shadow: 3px 3px 3px #ccc;\n  border-radius: 2px;\n}\n\n.order_tile__container {\n  display: flex;\n  justify-content: space-evenly;\n}\n\n.order_tile__card-container {\n  justify-content: center;\n}\n\n.order_tile__container p {\n  margin: auto auto;\n}\n\n.order_tile__button_container {\n  display: flex;\n  justify-content: space-evenly;\n}\n\n.order_tile__button {\n  padding: 10px 20px;\n  display: flex;\n}\n\n.order_tile__icon {\n  padding-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
