import React, { useState, useEffect } from "react";
import Card from "../UIElements/Card";
import axios from "axios";

import "./MainContactForm.css";

const MainContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [submitButtonText, setSubmitButtonText] = useState("Send Message");
  const handleSubmitForm = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/email/contact-us`,
        formData
      )
      .then((response) => {
        setFormData({ name: "", email: "", message: "" });
        setSubmitButtonText("Mesage Sent!");
      })
      .catch((err) => console.log(err));
  };

  const updateValue = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitButtonText("Submit Message");
    }, 3000);
  }, [submitButtonText]);
  return (
    <form className="contact-us__form_container" onSubmit={handleSubmitForm}>
      <h1>Contact Us</h1>
      <input
        type="text"
        name="name"
        placeholder="Your name"
        value={formData.name}
        onChange={updateValue}
      />
      <input
        type="email"
        name="email"
        placeholder="Your email"
        value={formData.email}
        onChange={updateValue}
      />
      <textarea
        type="text"
        name="message"
        placeholder="Your message"
        value={formData.message}
        onChange={updateValue}
        rows={5}
      />
      <button type="submit">{submitButtonText}</button>
    </form>
  );
};

export default MainContactForm;
