import React, { useEffect } from "react";

import "./ScheduleCustomCreation.css";

const ScheduleCustomCreation = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://cdn.oncehub.com/mergedjs/so.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="schedule__container">
    <h1>Discovery Call</h1>
    <p className="schedule__info">Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel ab ipsam laboriosam incidunt perspiciatis repudiandae distinctio at fugit minus ea?</p>
      <div
        id="SOIDIV_adapt-discovery"
        data-so-page="adapt-discovery"
        data-height="550"
        data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px; min-height: 90vh;"
        data-psz="00"
      ></div>
    </div>
  );
};

export default ScheduleCustomCreation;
