import React from "react";

import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaTiktok,
  FaPinterest,
  FaInstagram,
  FaSnapchat
} from "react-icons/fa";

import "./StandardSocialLink.css";

const StandardSocialLink = ({ profileData, platform, link }) => {
  const getIcon = () => {
    if (link.includes("facebook.com")) {
      return FaFacebook;
    } else if (link.includes("instagram.com")) {
      return FaInstagram;
    } else if (link.includes("twitter.com")) {
      return FaTwitter;
    } else if (link.includes("linkedin.com")) {
      return FaLinkedin;
    } else if (link.includes("youtube.com")) {
      return FaYoutube;
    } else if (link.includes("tiktok.com")) {
      return FaTiktok;
    } else if (link.includes("pinterest.com")) {
      return FaPinterest;
    } else if (link.includes("snapchat.com")) {
      return FaSnapchat;
    }
    return null;
  };

  const getUsernameFromURL = (url) => {
    const username = url.split("/").pop();
    return `@${username}`;
  };

  const Icon = getIcon();

  return Icon ? (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="standard_social_link__container"
    >
      <div className="standard_social_link__icon">
        <Icon size="60" />
      </div>
      <div className="standard_social_link__data">
        <h4 className="standard_social_link__network">{platform}</h4>
        <p>{getUsernameFromURL(link)}</p>
      </div>
    </a>
  ) : null;
};

export default StandardSocialLink;
