import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../context/auth-context";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../UIElements/ErrorModal";
import LoadingSpinner from "../UIElements/LoadingSpinner";
import StockCard from "../img/AdaptLogo.png";
import StandardCardImage from "../img/StandardImageCard.JPG";
import CustomStandardImageCard from "../img/CustomStandardImageCard.jpg";
import StandardMetalCardDisplay from "../img/StandardMetalCard.JPG";
import StandardMetalCard from "../img/adaptCardLogoMetal.png";
import CustomMetalCard from "../img/CustomMetalCard.JPG";

import Button from "../formElements/Button";

import "./CardReplacement.css";
import PaymentForm from "./PaymentForm";

const CardReplacement = () => {
  //constants
  //#region
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [hasPaid, setHasPaid] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const auth = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState(null);
  const [user, setUser] = useState();
  const [amount, setAmount] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [replacePaymentDetails, setReplacePaymentDetails] = useState(null);
  const [cardImage, setCardImage] = useState(StockCard);
  const [backCardImage, setBackCardImage] = useState(StockCard);
  const [success, setSuccess] = useState(false);
  const [type, setType] = useState(null);

  //#endregion

  //functions
  //#region
  //values for formik
  const initialValues = {
    cardimage: user ? (user.cardimage ? user.cardimage : "") : "",
    backcardimage: user ? (user.backcardimage ? user.backcardimage : "") : ""
  };

  //update values
  const updateUser = async (values) => {
    setIsLoading(true);
    const updateValues = JSON.stringify(values);
    const formData = new FormData();

    formData.append("cardimage", values.cardimage ? values.cardimage : "");
    formData.append(
      "backcardimage",
      values.backcardimage ? values.backcardimage : ""
    );

    setIsLoading(true);
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_API}/api/user/update-card/${auth.userId}`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: "Bearer " + auth.token
          }
        }
      )
      .then((response) => {
        setUserInfo(response.data.user);
        setCardImage(response.data.user.cardimage);
        setBackCardImage(response.data.user.backcardimage);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response.data.message);
        throw new Error(err.response.data.message);
      });
    setIsLoading(false);
  };

  const onHandleError = () => {
    setError(null);
  };

  const updateErrorMessage = (newError) => {
    setErrorMessage(newError);
  };

  const onUpdateSubmit = async (values) => {
    updateUser(values);
  };

  // const sendConfirmationEmail = () => {
  //   axios
  //     .post(`${process.env.REACT_APP_BACKEND_API}/api/email/replace-card`, {
  //       email: userInfo.email
  //     })
  //     .then((response) => {
  //       //console.log(response);
  //     })
  //     .catch((err) => {
  //       //console.log(err);
  //     });
  //};

  const createOrder = () => {
    const newOrder = {
      userId: auth.userId,
      name: replacePaymentDetails.shipping.name,
      email: userInfo.email,
      shippinglineone: replacePaymentDetails.shipping.address.line1,
      shippinglinetwo: replacePaymentDetails.shipping.address.line2,
      city: replacePaymentDetails.shipping.address.city,
      state: replacePaymentDetails.shipping.address.state,
      zipcode: replacePaymentDetails.shipping.address.postal_code,
      cardimage: userInfo !== null || "" ? userInfo.cardimage : "",
      backcardimage: userInfo !== null || "" ? userInfo.backcardimage : "",
      ordertotal: replacePaymentDetails.amount,
      dateordered: ""
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/api/order/create-order`,
        JSON.stringify(newOrder),
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          }
        }
      )
      .then()
      .catch((err) => {
        setError(err.message);
      });
  };

  //reset card image to Adapt logo
  const resetCardImage = (setFieldValue) => {
    setCardImage(StockCard);
    setFieldValue("cardimage", cardImage);
    setBackCardImage(StockCard);
    setFieldValue("backcardimage", backCardImage);
  };

  const handleSelection = (charge) => {
    setAmount(charge);
  };

  const cardImageDisplay = () => {
    switch (type) {
      case "StandardPVC":
        return cardImage;
      case "CustomPVC":
        return cardImage || user?.cardimage;
      case "StandardMetal":
        return StandardMetalCardDisplay;
      case "CustomMetal":
        return cardImage;
      default:
        return cardImage;
    }
  };
  //#endregion

  //useEffects
  //#region
  //get user info for card image
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/api/user/find-user/${auth.userId}`
      )
      .then((response) => {
        setUserInfo(response.data.user);
        setCardImage(response.data.user.cardimage);
        setBackCardImage(response.data.user.backcardimage);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API}/api/payment/config`)
      .then((response) => {
        setStripePromise(loadStripe(response.data.publishableKey));
      })
      .catch((err) => setError(err));
  }, []);

  useEffect(() => {
    if (amount && amount.amount > 0) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_API}/api/payment/pay`, amount)
        .then((response) => {
          setClientSecret(response.data.clientSecret);
        })
        .catch((err) => setError(err.message));
    }
  }, [amount]);

  useEffect(() => {
    if (replacePaymentDetails !== null) {
      formRef.current && formRef.current.submitForm();
      createOrder();
      //sendConfirmationEmail();
      setSuccess(true);
    }
  }, [replacePaymentDetails]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate(`/admin/${auth.userId}`);
      }, 2000);
    }
  }, [success]);

  useEffect(() => {
    if (errorMessage !== null || "") setError(true);
  }, [errorMessage]);

  //#endregion

  if (success) {
    return (
      <div className="update_user_card__success">
        <p>
          Success! Your new card has been ordered. You will be redirected to
          your admin page shortly.
        </p>
      </div>
    );
  }

  if (!amount) {
    return (
      <div className="update_user_card__selection">
        <div className="signup_form__selection">
          <h3 className="signup_form__standard_h3">
            Select Replacement Card Type
          </h3>
          <div className="signup_form__price_cards">
            <div className="signup_form__selection_option">
              <img src={StandardCardImage} alt="" />
              <h3>Adapt Card</h3>
              <p>NFC PVC Adapt Logo Card</p>
              <h4 className="signup_form__price_h4">$40</h4>
              <Button
                onClick={() => {
                  setType("StandardPVC");
                  handleSelection({ type: "StandardPVC", amount: 4000 });
                }}
              >
                Select
              </Button>
            </div>
            <div className="signup_form__selection_option">
              <img src={CustomStandardImageCard} alt="" />
              <h3>Custom Adapt Card</h3>
              <p>NFC PVC Custom Image Card</p>
              <h4 className="signup_form__price_h4">$60</h4>
              <Button
                onClick={() => {
                  setType("CustomPVC");
                  handleSelection({ amount: 6000 });
                }}
              >
                Select
              </Button>
            </div>
            <div className="signup_form__selection_option">
              <img src={StandardMetalCard} alt="" />
              <h3>Metal Adapt Card</h3>
              <p>NFC Metal Adapt Engraved Card</p>
              <h4 className="signup_form__price_h4">$90</h4>
              <Button
                onClick={() => {
                  setType("StandardMetal");
                  handleSelection({ amount: 9000 });
                }}
              >
                Select
              </Button>
            </div>
            <div className="signup_form__selection_option">
              <img src={CustomMetalCard} alt="" />
              <h3>Metal Custom Card</h3>
              <p>NFC Metal Custom Engraved Card</p>
              <h4 className="signup_form__price_h4">$110</h4>
              <Button
                onClick={() => {
                  setType("CustomMetal");
                  handleSelection({ amount: 11000 });
                }}
              >
                Select
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (userInfo) {
    return (
      <>
        <ErrorModal error={error} onClear={onHandleError}>
          {errorMessage}
        </ErrorModal>
        <div className="card_replacement__back-button">
          <Button
            className="card_replacement__back-button"
            onClick={() => navigate(`/admin/${auth.userId}`)}
            size="big"
          >
            Back
          </Button>
        </div>
        <div id="update_user_card-page">
          <div className="update_user_card-container">
            {isLoading && <LoadingSpinner asOverlay />}
            <h1>Order New Card</h1>
            <Formik
              initialValues={initialValues}
              onSubmit={onUpdateSubmit}
              innerRef={formRef}
            >
              {({
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                handleChange
              }) => (
                <Form
                  className="update_user-form"
                  //encType="multipart/form-data"
                  onSubmit={handleSubmit}
                >
                  <div className="">
                    <img
                      src={cardImageDisplay()}
                      className="update_card__contact-image"
                      alt="profile"
                      onError={() => setCardImage(StockCard)}
                    />
                    {type === "CustomPVC" || type === "CustomMetal" ? (
                      <input
                        type="file"
                        id="cardimage"
                        accept=".jpg,.png,.jpeg"
                        onChange={(e) => {
                          const fileReader = new FileReader();
                          fileReader.onload = () => {
                            if (fileReader.readyState === 2) {
                              setCardImage(fileReader.result);
                              setFieldValue("cardimage", e.target.files[0]);
                            }
                          };
                          fileReader.readAsDataURL(e.target.files[0]);
                        }}
                      />
                    ) : null}
                    <img
                      src={backCardImage != null ? backCardImage : StockCard}
                      className="update_card__contact-image"
                      alt="profile"
                      onError={() => setBackCardImage(StockCard)}
                    />
                    {type === "CustomPVC" ? (
                      <input
                        type="file"
                        id="backcardimage"
                        accept=".jpg,.png,.jpeg"
                        style={{
                          objectFit: "cover",
                          maxWidth: "500px",
                          marginLeft: "auto",
                          marginRight: "auto"
                        }}
                        onChange={(e) => {
                          const fileReader = new FileReader();
                          fileReader.onload = () => {
                            if (fileReader.readyState === 2) {
                              setBackCardImage(fileReader.result);
                              setFieldValue("backcardimage", e.target.files[0]);
                            }
                          };
                          fileReader.readAsDataURL(e.target.files[0]);
                        }}
                      />
                    ) : null}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="card_replacement__instruction">
          {" "}
          <h3>Order Now</h3>
          <p>
            Make sure your card image is updated before purchasing. The image
            that is in place when you order is what will be printed.
          </p>
        </div>
        <div className="card_replacement__stripe-payment">
          {amount && clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <PaymentForm
                amount={amount}
                formType="card-replacement"
                updateErrorMessage={updateErrorMessage}
                //sendConfirmationEmail={sendConfirmationEmail}
                setReplacePaymentDetails={setReplacePaymentDetails}
              />
            </Elements>
          )}
        </div>
      </>
    );
  }

  return <div>Loading</div>;
};

export default CardReplacement;
