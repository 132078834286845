// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update_vcard__contact-image img {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  border: 1px solid #ccc;
  object-fit: cover;
}
.update_vcard__contact-image {
  
  width: 200px;
  height: 200px;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.update_user-form label {
  margin: 5px 0;
  font-weight: 600;
}

.update_user-form label {
  margin: 5px 0;
  font-weight: 600;
}

.update_user-form input[type="file"]::file-selector-button {
  border: 1px solid #5fbcca;
  padding: 5px 30px;
  border-radius: 4px;
  background-color: #5fbcca;
  transition: 1s;
  margin-right: 5px;
  color: #fff;
}
#profileimage,
#backgroundimage {
  padding: 5px 0;
  margin: 10px 0;
}
input[type="file"]::file-selector-button:hover {
  background-color: #81ecec;
  border: 2px solid #00cec9;
}
`, "",{"version":3,"sources":["webpack://./src/shared/forms/UpdateVCardForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;AACnB;AACA;;EAEE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,WAAW;AACb;AACA;;EAEE,cAAc;EACd,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":[".update_vcard__contact-image img {\n  height: 150px;\n  width: 150px;\n  border-radius: 100%;\n  border: 1px solid #ccc;\n  object-fit: cover;\n}\n.update_vcard__contact-image {\n  \n  width: 200px;\n  height: 200px;\n  border-radius: 100%;\n  object-fit: cover;\n  overflow: hidden;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.update_user-form label {\n  margin: 5px 0;\n  font-weight: 600;\n}\n\n.update_user-form label {\n  margin: 5px 0;\n  font-weight: 600;\n}\n\n.update_user-form input[type=\"file\"]::file-selector-button {\n  border: 1px solid #5fbcca;\n  padding: 5px 30px;\n  border-radius: 4px;\n  background-color: #5fbcca;\n  transition: 1s;\n  margin-right: 5px;\n  color: #fff;\n}\n#profileimage,\n#backgroundimage {\n  padding: 5px 0;\n  margin: 10px 0;\n}\ninput[type=\"file\"]::file-selector-button:hover {\n  background-color: #81ecec;\n  border: 2px solid #00cec9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
