// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us__form_container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 8rem;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 2px #ccc;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
}

.contact-us__form_container button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #5fbcca;
  border-radius: 4px;
  background: #5fbcca;
  color: white;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/shared/forms/MainContactForm.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,4BAA4B;EAC5B,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".contact-us__form_container {\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 8rem;\n  border: 1px solid #ccc;\n  box-shadow: 2px 2px 2px #ccc;\n  padding: 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  max-width: 800px;\n}\n\n.contact-us__form_container button {\n  font: inherit;\n  padding: 0.5rem 1.5rem;\n  border: 1px solid #5fbcca;\n  border-radius: 4px;\n  background: #5fbcca;\n  color: white;\n  cursor: pointer;\n  text-decoration: none;\n  display: inline-block;\n  margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
