// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  margin: auto, auto;
  min-height: 80vh;
  min-width: 400px;
  width: 100%;
  height: 90%;
  margin: auto auto;
}
`, "",{"version":3,"sources":["webpack://./src/home/ContactUs.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;EACX,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".form-container {\n  margin: auto, auto;\n  min-height: 80vh;\n  min-width: 400px;\n  width: 100%;\n  height: 90%;\n  margin: auto auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
