import React from "react";
import AdaptLogo from "../../../shared/img/logo-img.png";
import "./ThemeFooter.css";

const ThemeFooter = () => {
  return (
    <a
      href="http://localhost:3000"
      className="theme_footer__link"
      target="_blank"
      rel="noreferrer"
    >
      <div className="theme_footer__container">
        <img src={AdaptLogo} className="theme_footer__logo" alt="" />
      </div>
    </a>
  );
};

export default ThemeFooter;
