import Button from "../../formElements/Button";
import StandardMetalCard from "../../img/adaptCardLogoMetal.png";

const CardImageSelect = ({
  type,
  cardImage,
  backCardImage,
  user,
  setCardImage,
  setBackCardImage,
  setFieldValue,
  resetCardImage,
  formIndex,
  setFormIndex
}) => {
  const cardImageDisplay = () => {
    switch (type) {
      case "StandardPVC":
        return cardImage;
      case "CustomPVC":
        return cardImage || user?.cardimage;
      case "StandardMetal":
        return StandardMetalCard;
      case "CustomMetal":
        return cardImage;
      default:
        return cardImage;
    }
  };
  return (
    <>
      <h3>Front</h3>
      <img className="cardimage" src={cardImageDisplay()} alt="cardimage" />
      {type === "CustomPVC" || type === "CustomMetal" ? (
        <input
          type="file"
          id="cardimage"
          accept=".jpg,.png,.jpeg"
          onChange={(e) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              if (fileReader.readyState === 2) {
                setCardImage(fileReader.result);
                setFieldValue("cardimage", e.target.files[0]);
              }
            };
            fileReader.readAsDataURL(e.target.files[0]);
          }}
        />
      ) : null}
      <h3>Back</h3>
      <img
        className="cardimage"
        src={backCardImage || user?.backcardimage}
        alt="backcardimage"
      />
      {type === "CustomPVC" ? (
        <input
          type="file"
          id="backcardimage"
          accept=".jpg,.png,.jpeg"
          onChange={(e) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              if (fileReader.readyState === 2) {
                setBackCardImage(fileReader.result);
                setFieldValue("backcardimage", e.target.files[0]);
              }
            };
            fileReader.readAsDataURL(e.target.files[0]);
          }}
        />
      ) : null}
      <div
        className="card_image__disclaimer"
        style={{ width: "80%", margin: "20px auto", fontSize: 12 }}
      >
        <p>Disclaimer*</p>
        <p>
          Metal NFC Cards: Custom images uploaded will be contacted by someone
          on our design team.
        </p>
        <p>
          Custom PVC Cards: Your card will be printed edge to edge in the way it
          is displayed above.
        </p>
        <p>
          QR codes will be different as each QR code is created specifically for
          your new profile. This generated QR code will be printed on the back
          of your Adapt Card.
        </p>
      </div>
      <Button
        type="button"
        size="l"
        onClick={() => resetCardImage(setFieldValue)}
        inverse
      >
        Clear Card Image
      </Button>
      <div className="next_button">
        <Button
          onClick={() => {
            setFormIndex(formIndex - 1);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            setFormIndex(formIndex + 1);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default CardImageSelect;
